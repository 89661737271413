import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
      variations: true
    },
    themes: {
      light: {
        primary: '#D30A10',
        secondary: '#990000',
        accent: '#FF533B',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
        background: '#ffffff',
      },
      dark: {
        primary: '#D30A10',
        secondary: '#990000',
        accent: '#FF533B',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
        background: '#242323',
      }
    }
  }
});

