<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container :fill-height="loading" class="deliveries" fluid pa-0>

    <v-app-bar app color="black" dark fixed>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          v-model="searchPhrase"
          :placeholder="`${$t('deliveries.search_for_deliveries')}...`"
          class="title text-uppercase topbar-search"
          color="grey"
          date
          full-width
          hide-details
          single-line
          style="font-size: 20px;"
        >
          <template v-slot:append>
            <hb-icon style="cursor: pointer;">search</hb-icon>
          </template>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="$vuetify.breakpoint.mdAndDown && $userHasMultipleAddresses" v-slot:extension>
        <hb-selected-address/>
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>


    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading" no-gutters>
        <v-alert v-if="$userHasMultipleAddresses" class="ma-4" outlined text type="info">
          {{ $t('orders.multiple_addresses_reminder') }}
        </v-alert>
        <v-col class="deliveries-holder" cols="12" no-gutters>

          <template
            v-if="mainDeliveries && mainDeliveries.length > 0 || smallDeliveries && smallDeliveries.length > 0 || mainDeliveriesLoading || smallDeliveriesLoading">
            <v-list v-if="mainDeliveries && mainDeliveries.length > 0 || mainDeliveriesLoading"
                    :three-line="$vuetify.breakpoint.xsOnly" color="transparent"
                    data-cy="deliveriesList">

              <v-list-item v-if="$vuetify.breakpoint.smAndUp">
                <v-list-item-content>
                  <v-container fluid pa-0>
                    <v-row no-gutters>
                      <v-col class="caption grey--text" sm="4">
                        {{ $t('deliveries.order_number') }}
                      </v-col>
                      <v-col class="caption grey--text" sm="4">
                        {{ $t('deliveries.delivery_date') }}
                      </v-col>
                      <v-col class="caption grey--text" sm="4">
                        {{ $t('deliveries.order_status') }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>

              <hb-loading-indicator v-if="mainDeliveriesLoading" align-middle fill-height/>
              <template v-else>
                <template v-if="mainDeliveries.length > 0">
                  <v-subheader class="font-weight-medium">
                    <hb-icon color="primary" left>mdi-truck</hb-icon>
                    {{ $t('deliveries.main_deliveries') }}
                  </v-subheader>

                  <template v-if="filteredDeliveries.length > 0">
                    <template v-for="delivery in filteredDeliveries">
                      <delivery-list-item :key="delivery.orderId"
                                          :bold="delivery.type && delivery.type !== 'Laatupoikkeama'"
                                          :delivery="delivery"
                                          :found="false"/>
                      <template v-for="subOrder in delivery.rows">
                        <delivery-list-item
                          v-if="subOrder.orderId.includes(searchPhrase.toLowerCase())"
                          :key="subOrder.orderId"
                          :bold="false"
                          :delivery="subOrder"
                          :found="searchPhrase.length > 0 && subOrder.orderId.includes(searchPhrase.toLowerCase())"
                          indent/>
                      </template>
                      <!--
                      <template v-if="delivery.containsBackorders">
                        <delivery-list-item :key="`backorder_${delivery.id}`" :backorder="true" :delivery="delivery" indent :bold="false" />
                      </template>
                      -->
                    </template>
                  </template>
                  <template v-else>
                    <v-alert class="mx-3" text type="info">
                      {{ $t('deliveries.couldnt_find_any_main_deliveries') }}
                    </v-alert>
                  </template>
                </template>
              </template>

            </v-list>

            <v-divider class="my-6"></v-divider>

            <template v-if="smallDeliveries.length || smallDeliveriesLoading">
              <v-list ref="smallDeliveriesList" class="other-deliveries" color="transparent"
                      data-cy="otherDeliveriesList">
                <hb-loading-indicator v-if="smallDeliveriesLoading" align-middle
                                      fill-height></hb-loading-indicator>
                <template v-else>

                  <v-subheader class="font-weight-medium">
                    <div>
                      <hb-icon color="primary" left>mdi-truck</hb-icon>
                      {{ $t('deliveries.others') }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="showHidden"
                      :label="$t('orders.show_delivered')"
                      class="caption"
                      data-cy="show-hidden-orders"/>
                  </v-subheader>

                  <v-list-item v-if="$vuetify.breakpoint.smAndUp">
                    <v-list-item-content>
                      <v-container fluid pa-0>
                        <v-row no-gutters>
                          <v-col class="caption grey--text" sm="4">
                            {{ $t('deliveries.order_number') }}
                          </v-col>
                          <v-col class="caption grey--text" sm="4">
                            {{ $t('deliveries.amount_and_product') }}
                          </v-col>
                          <v-col class="caption grey--text" sm="4">
                            {{ $t('deliveries.details') }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>

                  <template v-if="groupedBackordersKeys && groupedBackordersKeys.length > 0">
                    <template v-for="backorderGroup in groupedBackordersKeys">
                      <v-list-group :key="backorderGroup" :data-cy-back-order-group="backorderGroup"
                                    :value="expanded(groupedBackorders[backorderGroup])"
                                    sub-group>
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title v-if="backorderGroup == 0"
                                               :class="groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].delivered && groupedBackorders[backorderGroup][0].delivered === true && 'text--disabled'"
                                               class="font-weight-bold text-wrap">
                              {{ $t('orders.no_freight_assigned') }}
                            </v-list-item-title>
                            <v-list-item-title v-else
                                               :class="groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].delivered && groupedBackorders[backorderGroup][0].delivered === true && 'text--disabled'"
                                               class="font-weight-bold text-wrap">
                              {{ backorderGroup }}
                              <v-btn
                                v-if="$vuetify.breakpoint.smAndUp && groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].trackingCodeLink !== null"
                                class="ml-4"
                                color="primary"
                                dark
                                data-cy="follow-transport-button"
                                x-small
                                @click.stop="openTrackingPage(groupedBackorders[backorderGroup][0].trackingCodeLink)"
                              >
                                {{ $t('orders.follow_cargo_link') }}
                              </v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action v-if="$vuetify.breakpoint.xsOnly">
                            <v-btn
                              v-if="$vuetify.breakpoint.xsOnly && groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].trackingCodeLink !== null"
                              color="primary"
                              data-cy="follow-transport-button"
                              icon
                              @click.stop="openTrackingPage(groupedBackorders[backorderGroup][0].trackingCodeLink)"
                            >
                              <v-icon>mdi mdi-truck-fast-outline</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                        <v-card class="mx-5" elevation="0">
                          <template v-for="(backorder, index) in groupedBackorders[backorderGroup]">
                            <other-delivery-list-item :key="`${index+1234}_${backorder.drawingId}`"
                                                      :found="searchPhrase.length > 0"
                                                      :item="backorder"
                                                      backorder/>
                          </template>
                        </v-card>
                      </v-list-group>
                    </template>
                  </template>
                  <template v-else>
                    <v-alert class="mx-3" text type="info">
                      {{ $t('deliveries.couldnt_find_any_other_deliveries') }}
                    </v-alert>
                  </template>
                </template>
              </v-list>
            </template>
          </template>

          <template v-else>
            <v-col cols="12">
              <v-row align="center" justify="center" no-gutters>
                <div :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px'}"
                     class="text-center pl-5 pr-5"
                     style="padding-bottom: 150px;">
                  <hb-icon color="grey darken-3" size="100">mdi mdi-truck</hb-icon>
                  <div class="headline font-weight-bold mt-3 mb-3">{{
                      $t('deliveries.no_deliveries')
                    }}
                  </div>
                  <div class="subtitle-1 grey--text text--lighten-2">
                    {{ $t('deliveries.no_deliveries_help') }}.
                  </div>
                  <v-btn class="mt-5" outlined @click="$reloadApp">
                    <v-icon left>refresh</v-icon>
                    {{ $t('reload') }}
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </template>

        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import api from "@/apicollection";
import DeliveryListItem from "@/components/DeliveriesView/DeliveryListItem";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"
import _ from "lodash";
import OtherDeliveryListItem from "@/components/DeliveriesView/OtherDeliveryListItem";

dayjs.extend(customParseFormat)

export default {
  name: "DeliveriesView",
  components: {OtherDeliveryListItem, DeliveryListItem},
  props: {
    backorderId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      searchPhrase: '',
      mainDeliveriesLoading: false,
      smallDeliveriesLoading: false,
      mainDeliveries: [],
      smallDeliveries: [],
      showHidden: false,
    }
  },
  methods: {
    async reloadMainDeliveries() {
      if (!this.selectedAddress) {
        return;
      }

      this.mainDeliveriesLoading = true;
      try {
        const mainDeliveries = await api.getMainDeliveries(this.selectedAddress);
        this.mainDeliveries = mainDeliveries;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_deliveries'), true, this.reloadMainDeliveries);
      }
      this.mainDeliveriesLoading = false;
    },
    async reloadSmallDeliveries() {
      if (!this.selectedAddress) {
        return;
      }

      this.smallDeliveriesLoading = true;
      try {
        const smallDeliveries = await api.getSmallDeliveries(this.selectedAddress);
        this.smallDeliveries = smallDeliveries;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_deliveries'), true, this.reloadMainDeliveries);
      }
      this.smallDeliveriesLoading = false;
    },
    openTrackingPage(url) {
      window.open(url, 'blank')
    },
    expanded(ordersObject) {
      if (!this.backorderId) return false;
      return ordersObject[0].orderId === this.backorderId;
    }
  },
  watch: {
    selectedAddress(newValue, oldValue) {
      this.reloadMainDeliveries();
      this.reloadSmallDeliveries();
    },
  },
  computed: {
    selectedAddress() {
      return this.$store.state.selectedAddress;
    },
    filteredDeliveries() {
      const searchPhrase = this.searchPhrase.toLowerCase();
      return this.mainDeliveries.filter((d) =>
        d.orderId.toLowerCase().includes(searchPhrase) ||
        d.rows.map(or => or.orderId.toLowerCase()).join(' ').includes(searchPhrase)
      );
    },
    filteredBackorders() {
      const searchPhrase = this.searchPhrase.toLowerCase();
      return this.smallDeliveries.filter((bo) =>
        (bo.orderId && bo.orderId.toLowerCase().includes(searchPhrase)) ||
        (bo.colorDescription && bo.colorDescription.toLowerCase().includes(searchPhrase)) ||
        (bo.freightCompany && bo.freightCompany.toLowerCase().includes(searchPhrase)) ||
        (bo.materialDescription && bo.materialDescription.toLowerCase().includes(searchPhrase)) ||
        (bo.model && bo.model.toLowerCase().includes(searchPhrase)) ||
        (bo.modelDescription && bo.modelDescription.toLowerCase().includes(searchPhrase)) ||
        (bo.size && bo.size.toLowerCase().includes(searchPhrase))
      );
    },
    groupedBackordersKeys() {
      const filteredGroupedBackorders = Object.entries(this.groupedBackorders).filter(item => this.showHidden || item[1][0].delivered === false);
      const getDate = (e) => {
        const format = e[1][0].confirmedDeliveryDate ? 'DD.MM.YYYY' : "D.-.MM.YYYY";
        const date = e[1][0].confirmedDeliveryDate || e[1][0].deliveryEstimate;
        return dayjs(date, format);
      }


      filteredGroupedBackorders.sort((a, b) => getDate(a).diff(getDate(b)));
      return filteredGroupedBackorders.map(a => a[0]);

    },
    groupedBackorders() {
      return _.groupBy(this.filteredBackorders, (item) => {
        const freightCompany = item.freightCompany || this.$t('orders.no_freight_assigned');
        if (freightCompany) {
          let groupString = freightCompany;
          if (item.confirmedDeliveryDate) {
            groupString = `${groupString} - ${this.$t('orders.confirmed_delivery')}: ${item.confirmedDeliveryDate}`;
          } else if (item.deliveryEstimate) {
            groupString = `${groupString} - ${this.$t('orders.estimated_delivery_date')}: ${item.deliveryEstimate}`;
          }
          return groupString
        }
        return 0;
      });
    },
  },
  async mounted() {
    this.$gaPageView();
    await this.reloadMainDeliveries();
    await this.reloadSmallDeliveries();
    this.loading = false;

    if (this.backorderId) {
      this.$nextTick(() => {
        if (this.$refs.smallDeliveriesList) {
          this.$refs.smallDeliveriesList.$el.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }
      });
    }
  }
}
</script>

<style lang="scss">
.other-deliveries {
  .v-list-group--sub-group .v-list-group__header,
  .v-list-group--sub-group .v-list-group__items .v-list-item {
    padding: 0 16px;
  }
}
</style>
