<template>
  <v-container fluid :fill-height="loading" class="dashboard" pt-0 px-0>

    <v-app-bar color="black" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0;">
        <span class="title text-capitalize">MyPuustelli</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <PushNotificationConfirm ref="pushNotificationConfirm" />

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading" class="dashboard-holder" no-gutters>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pt-0">
              <v-img src="/img/Puustelli_etusivu_Juhlavuoden_kattaus_2020-60_main.jpg"
                     lazy-src="/img/Puustelli_etusivu_Juhlavuoden_kattaus_2020-60_main-small.jpg"
                     aspect-ratio="2.29"
              ></v-img>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" class="pt-0">
              <v-img src="/img/Klassinen_vaalea_l-keittiö_koivu_graniitti_Puustelli_0475_main.jpg"
                     lazy-src="/img/Klassinen_vaalea_l-keittiö_koivu_graniitti_Puustelli_0475_main-small.jpg"
                     aspect-ratio="2.29"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-container fluid>
          <v-row>
            <v-col cols="12" xl="10" offset-xl="1">
              <v-row>
                <v-col cols="12">
                  <v-alert
                    border="top"
                    colored-border
                    color="primary"
                    elevation="2"
                    class="mb-0 white"
                    icon="emoji_people"
                    prominent
                  >
                    <div class="title primary--text">{{ $t('dashboard.hello', [getHelloName]) }}! {{ newNotificationsMessage }}</div>
                  </v-alert>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-card outlined color="#191919">
                    <v-card-title>{{ $t('dashboard.notifications') }}</v-card-title>
                    <v-card-text>
                      <v-list color="transparent">
                        <v-slide-y-transition group>
                          <NotificationListItem v-for="notification in sortedRelevantNotifications" :notification="notification" :key="notification.id"></NotificationListItem>
                          <template v-if="showOlderNotifications && sortedOldNotifications.length > 0">
                            <template v-for="notification in sortedOldNotifications">
                              <v-lazy :options="{ threshold: 0.6 }"
                                      min-height="85"
                                      :key="notification.id"
                                      transition="fade-transition">
                                <NotificationListItem :notification="notification"></NotificationListItem>
                              </v-lazy>
                            </template>
                          </template>
                        </v-slide-y-transition>
                      </v-list>
                      <template v-if="!showOlderNotifications && sortedOldNotifications.length > 0">
                        <v-btn class="mt-3 mb-3" block outlined @click="showOlderNotifications = true">{{ $t('dashboard.show_more_notifications') }}</v-btn>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-fade-transition>

  </v-container>
</template>

<script>
  import apiCollection from "../apicollection";
  import NotificationListItem from "../components/DashboardView/NotificationListItem.vue";
  import PushNotificationConfirm from "@/components/PushNotificationConfirm";
  import moment from "moment";

  export default {
    name: "DashboardView",
    components: {
      PushNotificationConfirm,
      NotificationListItem,
    },
    data() {
      return {
        loading: true,
        notifications: [],
        showOlderNotifications: false,
      }
    },
    watch: {
      address(newValue, oldValue) {
        this.reloadNotifications();
      }
    },
    methods: {
      async reloadNotifications() {
        this.loading = true;
        try {
          if (this.address == null) {
            const notifications = await apiCollection.getNotifications(0);
            this.notifications = notifications;
          } else {
            const notifications = await apiCollection.getNotifications(this.address);
            this.notifications = notifications;
          }
        } catch (error) {
          console.log(error);
        }

        this.loading = false;
      }
    },
    computed: {
      getHelloName() {
        if (this.$store.state.impersonate) {
          return this.$store.state.impersonate.etunimi;
        } else {
          return this.$user.firstname;
        }
      },
      sortedNotifications() {
        const notifications = this.notifications;
        return notifications.sort((a, b) => {
          return a.status > b.status ? 1 : b.status > a.status ? -1 : 0;
        });
      },
      sortedRelevantNotifications() {
        const notifications = this.sortedNotifications;
        let relevantNotifications = notifications.filter(n => {
          if (n.status === 0) return true;
          return moment(n.createdAt).isSameOrAfter(moment().subtract(7, 'day'));
        });
        if (relevantNotifications.length === 0) {
          relevantNotifications = notifications.slice(0, 3);
        }
        return relevantNotifications;
      },
      sortedOldNotifications() {
        const oldNotifications = this.sortedNotifications.filter(n => this.sortedRelevantNotifications.findIndex((rn) => n.id === rn.id) < 0);
        return oldNotifications;
      },
      newNotificationCount() {
        const newNotifications = this.notifications.filter(n => n.status === 0);
        return newNotifications.length;
      },
      newNotificationsMessage() {
        if (this.newNotificationCount > 1) {
          return this.$t('dashboard.many_new_notifications', [this.newNotificationCount] );
        } else if (this.newNotificationCount === 1) {
          return this.$t('dashboard.one_new_notification');
        } else {
          return '';
        }
      },
      address() {
        return this.$store.state.selectedAddress;
      },
      user() {
        return this.$user;
      }
    },
    async mounted() {
      this.loading = false;

      this.$gaPageView();

      await this.reloadNotifications();

      if (!this.$isImpersonating) {
        if (!localStorage.getItem('pushNotificationsAsked')) {
          this.$refs.pushNotificationConfirm.open();
          localStorage.setItem('pushNotificationsAsked', 'true');
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
