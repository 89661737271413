import en from './languages/en.json';
import sv from './languages/sv-SE.json';
import fi from './languages/fi.json';

const messages = {
  en: en,
  fi: fi,
  sv: sv,
};

export default messages;
