<template>
  <v-container pa-0 fluid :fill-height="loading" class="offers">
    <v-app-bar color="black" dark fixed app>
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        <v-text-field
          :placeholder="`${$t('offers.search_for_offers')}...`"
          v-model="searchPhrase"
          class="title text-uppercase topbar-search"
          style="font-size: 20px"
          single-line
          full-width
          hide-details
          color="grey"
        >
          <hb-icon style="cursor: pointer" slot="append">search</hb-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension v-if="$vuetify.breakpoint.mdAndDown && $userHasMultipleAddresses">
        <hb-selected-address />
      </template>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition hide-on-leave>
      <v-row no-gutters v-if="!loading">
        <v-col cols="12" class="offers-holder">
          <v-list
            v-if="offers && offers.length > 0"
            color="transparent"
            :three-line="$vuetify.breakpoint.xsOnly"
            data-cy="offers_list"
          >
            <v-list-item v-if="$vuetify.breakpoint.smAndUp" data-cy="single_offer">
              <v-list-item-content>
                <v-container pa-0 ma-0 fluid>
                  <v-row no-gutters>
                    <v-col sm="7" class="caption grey--text">
                      {{ $t("offers.offer_number") }}
                    </v-col>
                    <v-col sm="5" class="caption grey--text">
                      {{ $t("offers.valid_until_date") }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>

            <template v-if="activeOffers.length > 0">
              <v-subheader class="font-weight-medium"
                ><hb-icon color="primary" left>assignment</hb-icon>
                {{ $t("offers.active_offers") }}</v-subheader
              >

              <template v-if="filteredActiveOffers.length > 0">
                <template v-for="offer in filteredActiveOffers">
                  <offer-list-item :key="offer.id" :offer="offer" />
                </template>
              </template>
              <template v-else>
                <v-alert class="mx-3" type="info" text>{{
                  $t("offers.couldnt_find_any_active_offers")
                }}</v-alert>
              </template>
            </template>

            <template v-if="pastOffers.length > 0">
              <v-subheader class="font-weight-medium" :class="activeOffers.length > 0 ? 'mt-5' : ''"
                ><hb-icon color="primary" left>history</hb-icon>
                {{ $t("offers.offers_history") }}</v-subheader
              >

              <template v-if="filteredPastOffers.length > 0">
                <template v-for="offer in filteredPastOffers">
                  <offer-list-item :key="offer.id" :offer="offer" />
                </template>
              </template>
              <template v-else>
                <v-alert class="mx-3" type="info" text>{{
                  $t("offers.couldnt_find_any_past_offers")
                }}</v-alert>
              </template>
            </template>
          </v-list>

          <template v-if="offers && offers.length === 0 && !loading">
            <v-col cols="12">
              <v-row justify="center" align="center" no-gutters>
                <div
                  class="text-center pl-5 pr-5"
                  style="padding-bottom: 150px"
                  :style="{ paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px' }"
                >
                  <hb-icon size="100" color="grey darken-3">assignment</hb-icon>
                  <div class="headline font-weight-bold mt-3 mb-3">
                    {{ $t("offers.no_offers") }}
                  </div>
                  <div class="subtitle-1 grey--text text--lighten-2">
                    {{ $t("orders.no_orders_help") }}.
                  </div>
                  <v-btn @click="$reloadApp" class="mt-5" outlined
                    ><v-icon left>refresh</v-icon> {{ $t("reload") }}</v-btn
                  >
                </div>
              </v-row>
            </v-col>
          </template>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import moment from "moment";
import OfferListItem from "../components/OffersView/OfferListItem.vue";
import api from "../apicollection";

export default {
  name: "OffersView",
  components: {
    OfferListItem: OfferListItem,
  },
  data() {
    return {
      loading: true,
      searchPhrase: "",
      offers: [],
    };
  },
  watch: {
    selectedAddress(newValue, oldValue) {
      this.reloadOffers();
    },
  },
  methods: {
    async reloadOffers() {
      if (!this.selectedAddress) {
        this.loading = false;
        return;
      }
      this.loading = true;
      try {
        const offers = await api.getOffers(this.selectedAddress);
        this.offers = offers;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(
          this.$t("errors.error_loading_offers"),
          true,
          this.reloadOffers
        );
      }
      this.loading = false;
    },
  },
  computed: {
    selectedAddress() {
      return this.$store.state.selectedAddress;
    },
    activeOffers() {
      const now = moment();
      console.log(this.offers.filter((offer) => now.isBefore(offer.expirationDate)));
      return this.offers.filter((offer) => now.isBefore(offer.expirationDate));
    },
    filteredActiveOffers() {
      const searchPhrase = this.searchPhrase.toLowerCase();
      return this.activeOffers.filter((d) => d.id.toLowerCase().includes(searchPhrase));
    },
    pastOffers() {
      const now = moment();
      return this.offers.filter((offer) => now.isAfter(offer.expirationDate));
    },
    filteredPastOffers() {
      const searchPhrase = this.searchPhrase.toLowerCase();
      return this.pastOffers.filter((d) => d.id.toLowerCase().includes(searchPhrase));
    },
  },
  mounted() {
    this.$gaPageView();
    this.reloadOffers();
  },
};
</script>

<style lang="scss" scoped></style>
