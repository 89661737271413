/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        }
      },
      methods: {
        $gaPageView(page) {
          let pagePath = page;
          if (pagePath == null) {
            pagePath = this.$router.currentRoute.path;
          }
          this.$gaEvent('page_view', {
            page_path: pagePath,
          })
        },
        $gaEvent(action, eventData) {
          if ('gtag' in window) {
            window.gtag('event', action, eventData);
          } else {
            console.log(`[DEBUG] google analytics event`, {
              event: 'event',
              action,
              eventData,
            });
          }
        },
        $reloadApp() {
          location.reload();
        },
        $showErrorNotification(text, useCallback = true, callback) {
          this.$store.dispatch('showNotification',
            {
              color: 'error',
              message: text,
              timeOut: useCallback ? 10000 : 4000,
              showButton: useCallback,
              buttonText: this.$t('try_again'),
              callback: useCallback ? callback : () => {},
            });
        },
        $showSuccessNotification(text, useCallback = false, callback) {
          this.$store.dispatch('showNotification',
            {
              color: 'success',
              message: text,
              timeOut: useCallback ? 10000 : 4000,
              showButton: useCallback,
              buttonText: this.$t('try_again'),
              callback: useCallback ? callback : () => {},
            });
        },
        $handleApiError(error, errorMessage = null) {
          console.log(error, {errorMessage});
          const message = errorMessage || this.$t('api_error');

          this.$showErrorNotification(message, false);
        },
        $getDocumentIcon(document) {
          let re = /(?:\.([^.]+))?$/;
          let filetype = '';
          if (document.filename) {
            filetype = re.exec(document.filename)[1]?.toLowerCase();
          } else {
            filetype = re.exec(document)[1]?.toLowerCase();
          }
          switch (filetype) {
            case 'doc':
              return 'far fa-file-word';
            case 'docx':
              return 'far fa-file-word';
            case 'pdf':
              return 'far fa-file-pdf';
            case 'jpg':
              return 'far fa-file-image';
            case 'png':
              return 'far fa-file-image';
            case 'gif':
              return 'far fa-file-image';
            case 'ppt':
              return 'far fa-file-powerpoint';
            case 'pptx':
              return 'far fa-file-powerpoint';
            case 'xls':
              return 'far fa-file-excel';
            case 'xlsx':
              return 'far fa-file-excel';
            case 'cvs':
              return 'far fa-file-excel';
          }
          return 'far fa-file';
        },
        $formatNumberTo2Decimals(number, userLocale = 'fi') {
          return Number(number).toLocaleString(userLocale, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },
      }
    });
  },
};

export default HelperPlugin;
