<script>
import apicollection from "@/apicollection";

export default {
  name: "ImpersonateEndedView",
  data() {
    return {
      loading: true,
      // navigationEnforcer: false,
      showNavigationAlert: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    // if (this.navigationEnforcer) {
    //   next();
    // } else {
      next(false);
      this.showNavigationAlert = true;
    // }
  },
  // methods: {
  //   closeTab() {
  //     window.close();
  //     this.$nextTick(() => {
  //       console.log('täällä ollaan edelleen.')
  //     })
  //   }
  // },
  computed: {
    rootURL() {
      return window.location.origin;
    }
  },
  async created() {
    if (!this.$isImpersonating) {
      window.location.assign(this.rootURL);
    }

    this.$store.state.showFullPage = false;
    try {
      try {
        await apicollection.endImpersonation();
      } catch (ignored) {
        console.log(ignored);
      }
      this.$store.commit('clearImpersonateData');

      this.$showSuccessNotification(this.$t('impersonate.session_ended_success'));
    } catch (e) {
      console.log(e)
    }
    this.loading = false;
  },
  beforeDestroy() {
    this.$store.state.showFullPage = true;
  }
}
</script>

<template>
 <v-container :fill-height="loading" class="impersonate-ended">
   <v-app-bar color="black" dark fixed app>

     <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $t('impersonate.session_ended') }}</v-toolbar-title>

     <v-spacer></v-spacer>

   </v-app-bar>

   <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

   <v-fade-transition hide-on-leave>
     <v-row v-if="!loading" no-gutters>
       <div>
         <h1>{{$t('impersonate.session_ended')}}</h1><br />
         <h2>{{$t('impersonate.ended.header')}}</h2><br />
         <v-alert v-if="showNavigationAlert" class="mb-6" outlined type="error">
           {{$t('impersonate.ended.navigation_error')}}
         </v-alert>
<!--         <v-btn class="primary" :href="rootURL" @click="navigationEnforcer = true">{{$t('impersonate.ended.action')}}</v-btn>-->
<!--         <v-btn class="primary" @click="closeTab">{{$t('impersonate.ended.action')}}</v-btn>-->
       </div>
     </v-row>
   </v-fade-transition>
 </v-container>
</template>

<style scoped lang="scss">

</style>
