<template>
  <div>
  </div>
</template>

<script>
  export default {
    name: 'DefaultView',
    watch: {
      selectedAddress(newValue, oldValue) {
        this.goToSelectedAddress();
      }
    },
    methods: {
      goToSelectedAddress() {
        const aid = this.selectedAddress == null ? 0 : this.selectedAddress;
        this.$router.push({name: 'dashboard', params: {addressId: aid }});
      }
    },
    computed: {
      selectedAddress() {
        return this.$store.state.selectedAddress;
      }
    },
    mounted() {
      this.goToSelectedAddress();
    }
  }
</script>

<style scoped>

</style>
