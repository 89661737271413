<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid :fill-height="loading" class="webshop">

    <v-app-bar color="black" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $t('webshop.title') }}</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading">
        <v-col cols="12" xl="10" offset-xl="1" class="webshop-holder">

          <v-row>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919">
                    <v-responsive>
                      <v-img class="black" lazy-src="/img/verkkokauppa_thumb.jpg" aspect-ratio="1.935483870967742" src="/img/verkkokauppa.jpg" :alt="$t('webshop.webshop_image')" />
                    </v-responsive>
                    <v-card-title class="headline font-weight-bold">
                      {{ $t('webshop.webshop') }}
                    </v-card-title>
                    <v-card-text>
                      {{ $t('webshop.webshop_introduction') }}
                    </v-card-text>
                    <v-card-text>
                      <template v-if="$user.language === 'sv'">
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               class="mb-3"
                               block
                               color="primary"
                               :href="webShopUrlSv"
                               target="_blank">
                          {{ $t('webshop.go_to_swedish_webshop') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               block
                               color="accent"
                               outlined
                               :href="webShopUrlFi"
                               target="_blank">
                          {{ $t('webshop.go_to_finnish_webshop') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               class="mb-3"
                               block
                               color="primary"
                               :href="webShopUrlFi"
                               target="_blank">
                          {{ $t('webshop.go_to_finnish_webshop') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               block
                               color="accent"
                               outlined
                               :href="webShopUrlSv"
                               target="_blank">
                          {{ $t('webshop.go_to_swedish_webshop') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919">
                    <v-responsive>
                      <v-img class="black" lazy-src="/img/asennuspalvelut_thumb.jpg" aspect-ratio="1.935483870967742" src="/img/asennuspalvelut.jpg" :alt="$t('webshop.installation_services_image')" />
                    </v-responsive>
                    <v-card-title class="headline font-weight-bold">{{ $t('webshop.installation_services') }}</v-card-title>
                    <v-card-text>
                      {{ $t('webshop.installation_services_introduction') }}
                    </v-card-text>
                    <v-card-text>
                      <template v-if="$user.language === 'sv'">
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               :class="$vuetify.breakpoint.xsOnly ? 'smaller-mobile-text' : ''"
                               class="mb-3"
                               block
                               color="primary"
                               :href="installationServiceUrlSv"
                               target="_blank">
                          {{ $t('webshop.book_installation_in_sweden') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               :class="$vuetify.breakpoint.xsOnly ? 'smaller-mobile-text' : ''"
                               block
                               color="accent"
                               outlined
                               :href="installationServiceUrlFi"
                               target="_blank">
                          {{ $t('webshop.book_installation_in_finland') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               :class="$vuetify.breakpoint.xsOnly ? 'smaller-mobile-text' : ''"
                               class="mb-3"
                               block
                               color="primary"
                               :href="installationServiceUrlFi"
                               target="_blank">
                          {{ $t('webshop.book_installation_in_finland') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                        <v-btn :small="$vuetify.breakpoint.xsOnly"
                               :class="$vuetify.breakpoint.xsOnly ? 'smaller-mobile-text' : ''"
                               block
                               color="accent"
                               outlined
                               :href="installationServiceUrlSv"
                               target="_blank">
                          {{ $t('webshop.book_installation_in_sweden') }}
                          <hb-icon size="15" right>mdi mdi-arrow-right</hb-icon>
                        </v-btn>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-col>
      </v-row>
    </v-fade-transition>


  </v-container>
</template>

<script>
export default {
  name: "Webshop",
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    webShopUrlFi() {
      if ('VUE_APP_WEBSHOP_URL' in process.env) {
        return process.env.VUE_APP_WEBSHOP_URL;
      }
      return 'https://webstore.puustelli.com/fi/';
    },
    webShopUrlSv() {
      if ('VUE_APP_WEBSHOP_URL_SV' in process.env) {
        return process.env.VUE_APP_WEBSHOP_URL_SV;
      }
      return ''
    },
    installationServiceUrlFi() {
      if ('VUE_APP_BOOK_INSTALLATION_URL' in process.env) {
        return process.env.VUE_APP_BOOK_INSTALLATION_URL;
      }
      return 'https://www.puustelli.fi/lomakkeet/tilaa-keittion-kunnossapitotyo';
    },
    installationServiceUrlSv() {
      if ('VUE_APP_BOOK_INSTALLATION_URL_SV' in process.env) {
        return process.env.VUE_APP_BOOK_INSTALLATION_URL_SV;
      }
      return 'https://www.puustelli.se/form/boka-kokets-servicebesok';
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style scoped>
.smaller-mobile-text {
  font-size: 10px !important;
}
</style>
