<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <div>
    <v-container fluid :fill-height="loading" class="user-settings">

      <v-app-bar color="black" dark fixed app>

        <hb-back-button fallback="orders" />

        <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $t('user_settings.title') }}</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-app-bar>

      <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

      <v-fade-transition hide-on-leave>
        <v-row v-if="!loading" no-gutters>
          <v-col cols="12" xl="8" offset-xl="2" class="user-settings-holder">

            <v-row>

              <v-col cols="12" md="6">
                <v-card outlined color="#191919">
                  <v-card-title primary-title class="pb-0">
                    <div>
                      <div class="mb-0">{{ $t('user_settings.profile') }}</div>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn text color="accent" @click="userSettings">{{ $t('user_settings.edit_user_profile') }}</v-btn>
                  </v-card-actions>
                  <v-card-text class="pb-0">
                    <div class="subtitle-1 white--text">{{ $t('user_settings.privacy_statement') }}</div>
                    {{ $t('user_settings.privacy_statement_explained') }}
                  </v-card-text>
                  <v-card-actions>
                    <div>
                      <v-btn text color="accent" href="https://www.puustelli.fi/tietosuojaseloste" target="_blank">{{ $t('user_settings.open_privacy_statement_fi') }}</v-btn><br />
                      <v-btn text color="accent" href="https://www.puustelli.se/integritetspolicy" target="_blank">{{ $t('user_settings.open_privacy_statement_sv') }}</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card outlined color="#191919">
                  <v-card-title primary-title class="pb-0">
                    <div>
                      <div class="mb-0">{{ $t('user_settings.push_notifications') }}</div>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    {{ $t('user_settings.push_notifications_explained') }}
                  </v-card-text>
                  <v-card-text>
                    <div class="subtitle-1 white--text">{{ $t('user_settings.account_notifications') }}</div>
                    {{ $t('user_settings.account_notifications_explained') }}
                  </v-card-text>
                  <v-card-actions>
                    <v-switch
                      v-model="pushNotificationsEnabled"
                      @change="updateNotificationSettings"
                      color="primary"
                      class="mt-0 mb-2 ml-2"
                      hide-details
                      :label="$t('user_settings.allow_notifications')"
                    ></v-switch>
                  </v-card-actions>
                  <v-card-text>
                    <div class="subtitle-1 white--text">{{ $t('user_settings.device_notifications') }}</div>
                    <template v-if="pushNotificationsAreAllowedOnThisDevice">
                      <p class="body-2">{{ $t('user_settings.notifications_allowed_in_browser_settings') }}</p>
                    </template>
                    <template v-if="pushNotificationsAreDeniedOnThisDevice">
                      <p class="body-2">{{ $t('user_settings.notifications_denied_in_browser_settings') }}</p>
                    </template>
                    <template v-if="pushNotificationsHaveNotBeenAskedOnThisDevice">
                      <p class="body-2">{{ $t('user_settings.notifications_not_asked') }}</p>
                      <v-btn @click="enableNotifications" outlined small text>{{ $t('user_settings.allow_notifications') }}</v-btn>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>

            </v-row>

          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>
  </div>
</template>

<script>
  import api from '../apicollection';
  import Notifications from '../notifications';
  import apicollection from "../apicollection";
  import auth from "@/auth";

  export default {
    name: "UserSettingsView",
    data() {
      return {
        loading: false,
        pushNotificationsEnabled: false,
        notificationBrowserPermissions: false,
      }
    },
    methods: {
      userSettings() {
        const url = auth.client.createAccountUrl();
        window.location = `${url}&kc_locale=${this.$user.language}`;
      },
      async updateNotificationSettings() {
        try {
          const user = await api.updateUser(this.$user.id, {
            language: this.$user.language,
            pushNotificationsEnabled: this.pushNotificationsEnabled,
          });
          this.$setUser(user);
        } catch (e) {
          console.log(e);
          this.$showErrorNotification(this.$t('errors.error_updating_push_notification_settings'), false);
        }
        this.pushNotificationsEnabled = this.$user.pushNotificationsEnabled;
      },
      async enableNotifications() {
        const notificationPermission = await Notifications.registerNotifications();
        if (notificationPermission) {
          try {
            await apicollection.updateUser(this.$user.id, { language: this.$user.language, pushNotificationsEnabled: true });
            this.pushNotificationsEnabled = this.$user.pushNotificationsEnabled;
            this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();
          } catch (error) {
            console.log(error);
            this.$showErrorNotification(this.$t('errors.error_updating_push_notification_settings'));
          }
        } else {
          this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();
        }
      },
    },
    computed: {
      pushNotificationsHaveNotBeenAskedOnThisDevice() {
        return this.notificationBrowserPermissions === 'default';
      },
      pushNotificationsAreDeniedOnThisDevice() {
        return !this.notificationBrowserPermissions || this.notificationBrowserPermissions === 'denied' || this.notificationBrowserPermissions === 'disabled';
      },
      pushNotificationsAreAllowedOnThisDevice() {
        return this.notificationBrowserPermissions === 'granted';
      },
    },
    mounted() {
      this.$gaPageView();
      this.pushNotificationsEnabled = this.$user.pushNotificationsEnabled;
      this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();

      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      });
    },
  }
</script>

<style lang="scss" scoped>
</style>
