<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid pt-0 :fill-height="loading" class="delivery-preparation">

    <v-app-bar color="black" dark fixed app>

      <hb-back-button fallback="orders" />

      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $t('delivery_info.delivery_preparation') }}<span class="grey--text lighten-5">{{ deliveryData && deliveryData.orderId ? ` #${deliveryData.orderId}` : ''}}</span></v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading">
        <v-col cols="12" xl="8" offset-xl="2" class="delivery-preparation">
          <v-row>

            <v-col cols="12">
              <v-alert type="info" color="primary" :value="true" class="white mb-0 mt-3" light border="top" colored-border prominent>
                <div class="title my-2 primary--text">{{ $t('delivery_info.delivery_date_is_approaching') }}</div>
                <div class="subtitle-1 my-2 primary--text">{{ $t('delivery_info.important_information_about_delivery_below') }}</div>
                <!--<div class="subtitle-1 my-2 primary--text">{{ $t('delivery_info.manufacturing_is_in_process', { orderId: deliveryData.orderId}) }}:</div>
                <div>
                  <span class="fixed-width">{{ $t('delivery_info.delivery_date') }}:</span> {{ deliveryData.deliveryDate }}
                  <template v-if="deliveryData.deliveryTime">
                    <br />
                    <span class="fixed-width">{{ $t('delivery_info.estimated_delivery_time') }}:</span> {{ deliveryData.deliveryTime }}<br />
                  </template>
                </div> -->
              </v-alert>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <v-card outlined color="#191919" class="fill-height">
                <v-card-title><div style="word-break: normal;">{{ $t('delivery_info.manufacturing_is_in_process', { orderId: deliveryData.orderId}) }}:</div></v-card-title>
                <v-card-text>
                  <div class="body-1">
                    {{ $t('delivery_info.delivery_date') }}: {{ deliveryData.deliveryDate }}<br />
                    <template v-if="deliveryData.deliveryTime">{{ $t('delivery_info.estimated_delivery_time') }}: {{ deliveryData.deliveryTime }}<br /></template>
                  </div>
                </v-card-text>
                <v-card-title><div style="word-break: normal;">{{ $t('delivery_info.check_delivery_address') }}:</div></v-card-title>
                <v-card-text>
                  <div class="body-1">
                    {{ deliveryData.customerName }}<br />
                    {{ deliveryData.address1 }}<br />
                    {{ deliveryData.address2 }}<br />
                    <span v-if="deliveryData.customerPhone">{{ $t('delivery_info.phone') }} {{ deliveryData.customerPhone }}<br /></span>
                    <span v-else>{{ $t('delivery_info.phone_unavalaible') }}<br /></span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <v-card outlined color="#191919" class="fill-height">
                <v-card-title><div style="word-break: normal;">{{ $t('delivery_info.questions_about_delivery') }}</div></v-card-title>
                <v-card-text>
                  <div class="body-1">
                    {{ $t('delivery_info.questions_about_delivery_2', { deliveryCarPhone: deliveryData.deliveryCarPhone, deliveryCompanyPhone: deliveryData.deliveryCompanyPhone }) }}
                  </div>

                  <v-list color="transparent">
                    <v-list-item :href="deliveryData.deliveryCarPhone ? `tel:${deliveryData.deliveryCarPhone}` : ''">
                      <v-list-item-avatar color="primary"><v-icon size="16">fas fa-truck</v-icon></v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('delivery_info.carrier_truck') }}</v-list-item-title>
                        <v-list-item-subtitle v-if="deliveryData.deliveryCarPhone">{{ $t('delivery_info.phone') }} {{ deliveryData.deliveryCarPhone }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>{{ $t('delivery_info.phone_unavalaible') }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :href="deliveryData.deliveryCompanyPhone ? `tel:${deliveryData.deliveryCompanyPhone}` : ''">
                      <v-list-item-avatar color="primary"><hb-icon>emoji_people</hb-icon></v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('delivery_info.carrier_customer_service') }}</v-list-item-title>
                        <v-list-item-subtitle v-if="deliveryData.deliveryCompanyPhone">{{ $t('delivery_info.phone') }} {{ deliveryData.deliveryCompanyPhone }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>{{ $t('delivery_info.phone_unavalaible') }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-card outlined color="#191919">
                <v-card-title><div style="word-break: normal;">{{ $t('delivery_info.buyer_must_take_care') }}</div></v-card-title>
                <v-card-text>
                  <ul>
                    <li>{{ $t('delivery_info.buyer_obligation1') }}</li>
                    <li>{{ $t('delivery_info.buyer_obligation2') }}</li>
                    <li>{{ $t('delivery_info.buyer_obligation3') }}</li>
                    <li>{{ $t('delivery_info.buyer_obligation4') }}</li>
                  </ul>
                  <div class="mt-4">{{ $t('delivery_info.contact_if_obligations_cannot_be_met', {sellerPhone: deliveryData.sellerPhone, storePhone: deliveryData.storePhone, customerServicePhone: deliveryData.customerServicePhone}) }}</div>
                  <div class="mt-4">{{ $t('delivery_info.moving_delivery_date') }}</div>
                  <div class="mt-4">{{ $t('delivery_info.thanks_for_your_order') }}</div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col v-if="deliveryData && deliveryData.youtubeUrl" cols="12" class="pt-0">
              <v-responsive :aspect-ratio="16/9" class="video-wrapper">
                <youtube :video-id="youTubeIdGenerator"></youtube>
              </v-responsive>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>


  </v-container>
</template>

<script>
  import api from '../apicollection';

  import Vue from 'vue'
  import VueYouTubeEmbed from 'vue-youtube-embed'
  Vue.use(VueYouTubeEmbed)

  export default {
    name: "DeliveryPreparationView",
    props: {
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        deliveryData: null,
      }
    },
    methods: {
      async reloadDeliveryData() {
        this.loading = true;
        try {
          const deliveryData = await api.getOrderDeliveryData(this.id);
          this.deliveryData = deliveryData;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_delivery_data'), true, this.reloadDeliveryData);
        }
        this.loading = false;
      },
    },
    computed: {
      youTubeIdGenerator() {
        return this.$youtube.getIdFromURL(this.deliveryData.youtubeUrl);
      },
    },
    mounted() {
      this.reloadDeliveryData();
      this.$gaPageView();

      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      });
    },
  }
</script>

<style lang="scss" scoped>
  .fixed-width {
    display: inline-block;
    width: 115px;
  }
</style>

<style lang="scss">
  .video-wrapper {
    position: relative;
    iframe {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
</style>
