<template>
  <v-list-item :disabled="$isImpersonating" @click="openNotification">
    <v-list-item-icon>
      <hb-icon :color="color">{{ icon }}</hb-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
      <v-list-item-subtitle class="text-wrap notification-msg" v-html="message"></v-list-item-subtitle>
      <v-list-item-subtitle class="mt-1"><v-chip color="#333" x-small label style="padding-top: 1px;">{{ $formatDate(notification.createdAt) }}</v-chip></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import apicollection from "../../apicollection";

  export default {
    props: {
      notification: {
        type: Object,
      },
    },
    data() {
      return {
        isActive: false,
      }
    },
    methods: {
      async openNotification() {
        await apicollection.openNotification(this.notification.id);
        const url = this.notification.redirectUrl;
        if (url != null && url !== '') {
          window.location = url;
        } else {
          this.notification.status = 10;
        }
      }
    },
    computed: {
      notificationParams() {
        const notificationParams = this.notification.notificationParams;
        if (notificationParams == null || notificationParams === '') {
          return {};
        }

        try {
          return JSON.parse(notificationParams);
        } catch (error) {
          console.log('error parsing notification params', error);
          return {};
        }
      },
      title() {
        if (this.notification.titleKey != null) {
          return this.$t(this.notification.titleKey, this.notificationParams);
        }
        return this.notification.title;
      },
      message() {
        if (this.notification.messageKey != null) {
          return this.$t(this.notification.messageKey, this.notificationParams);
        }
        return this.notification.message;
      },
      url() {
        return "/api/Invitation/" + this.notification.id;
      },
      icon() {
        if (this.notification.type === 'document-added') {
          return 'insert_drive_file';
        }

        return 'assignment_turned_in';
      },
      color() {
        if (this.notification.status === 0) {
          return 'primary';
        } else {
          return '';
        }
      }
    },
  }
</script>

<style lang="scss">
.notification-msg {
  p:last-child {
    margin-bottom: 0;
  }
}
</style>
