import { render, staticRenderFns } from "./WarrantyCertificateView.vue?vue&type=template&id=3c3fe9c4&scoped=true&xmlns%3Av-touch=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./WarrantyCertificateView.vue?vue&type=script&lang=js&"
export * from "./WarrantyCertificateView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c3fe9c4",
  null
  
)

export default component.exports