<template>
  <v-treeview :dense="$vuetify.breakpoint.xsOnly"
              :filter="filter"
              :items="documents"
              :open.sync="open"
              :search="searchPhrase"
              activatable
              data-cy="documents_tree_structure"
              hoverable
              open-all
              open-on-click>
    <template v-slot:prepend="{ item, open }">
      <v-badge :value="isNew(item)" bordered color="primary" dot overlap>
        <v-icon v-if="!item.url">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <v-icon v-else color="primary" size="22">
          {{ $getDocumentIcon(item) }}
        </v-icon>
      </v-badge>
    </template>
    <template v-slot:label="{ item }">
      <document-list-item v-if="item.url" :compact="compact" :document="item" @delete="(document) => {
                deleteDocumentFromList;
                reloadDocuments
              }"/>
      <v-subheader v-else class="font-weight-medium">{{ item.name }}</v-subheader>
    </template>
  </v-treeview>
</template>
<script>
import DocumentListItem from "../components/DocumentsView/DocumentListItem.vue"
import moment from "moment/moment";

export default {
  name: 'document-tree-view',
  components: {DocumentListItem},
  props: {
    documents: {
      type: Array,
      required: true,
    },
    searchPhrase: {
      type: String,
      required: false,
      default: null,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      open: [],
    }
  },
  methods: {
    reloadDocuments() {
      this.$emit('reload-documents');
    },
    isNew(document) {
      const documentDate = moment(document.updatedAt);
      const compareDate = moment().subtract(7, 'day');
      return documentDate.isAfter(compareDate);
    },
    filter(item, search) {
      const searchPhraseLower = search.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(" ");

      let found = [];
      const lowerSearchedString =
        (item.filename != null ? item.filename.toLowerCase() : '') +
        (item.updatedAt != null ? item.updatedAt.toString().toLowerCase() : '') +
        (item.type != null ? item.type.toLowerCase() : '');
      searchPhraseArray.forEach(phrase => {
        if (lowerSearchedString.indexOf(phrase) !== -1) {
          found.push(true);
        } else {
          found.push(false);
        }
      })
      return found.indexOf(false) === -1;
    },
    deleteDocumentFromList(document) {
      let docIndex = this.documents.findIndex(d => d.id === document.id);
      if (docIndex > -1) this.documents.splice(docIndex, 1);
    },
  },
}
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
@import '~vuetify/src/styles/settings/_variables.scss'

$xs-breakpoint: #{map-get($grid-breakpoints, 'sm') - 0.02}

@media (max-width: #{$xs-breakpoint})
  .v-treeview-node__level
    width: 12px !important
</style>
