<template>
  <div v-if="delivery">

    <v-list-item :class="found ? 'delivery-item-found' : ''"  :href="getOrderUrl">
      <v-list-item-content>

        <!-- SM AND UP -->
        <v-container pa-0 fluid v-if="$vuetify.breakpoint.smAndUp">
          <v-row no-gutters>
            <v-col sm="4" class="font-weight" :class="bold ? 'font-weight-bold' : ''">
              <div :class="indent ? 'ml-3' : ''">{{ delivery.type ? delivery.type : backorder ? $t('orders.in_backorder') : $tc('orders.title', 1) }} <span class="grey--text text--darken-1">#{{ delivery.orderId }}</span></div>
            </v-col>
            <template v-if="!backorder">
              <v-col sm="4" class="caption grey--text darken-1">
                {{ delivery.deliveryDate }}
              </v-col>
              <v-col sm="4" class="caption grey--text darken-1">
                {{ delivery.status }}
              </v-col>
            </template>
          </v-row>
        </v-container>

        <!-- XS ONLY -->
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-list-item-title :class="bold ? 'font-weight-bold' : ''">
            <div :class="indent ? 'ml-2' : ''">{{ delivery.type ? delivery.type : backorder ? $t('orders.in_backorder') : $tc('orders.title', 1) }} #{{ delivery.orderId }}</div>
          </v-list-item-title>
          <template v-if="!backorder">
            <v-list-item-subtitle class="caption grey--text darken-1">
              <div :class="indent ? 'ml-2' : ''">{{ $t('deliveries.delivery_date') }}: {{ delivery.deliveryDate }}</div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="caption grey--text darken-1">
              <div :class="indent ? 'ml-2' : ''">{{ $t('deliveries.order_status') }}: {{ delivery.status }}</div>
            </v-list-item-subtitle>
          </template>
        </template>

      </v-list-item-content>

    </v-list-item>

  </div>
</template>

<script>
  export default {
    name: "DeliveryListItem",
    props: {
      delivery: {
        type: [Object, Boolean],
        default: false,
      },
      backorder: {
        type: [Boolean],
        default: false,
      },
      indent: {
        type: Boolean,
        default: false,
      },
      bold: {
        type: Boolean,
        default: true,
      },
      found: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      getOrderUrl() {
        if (!this.delivery.orderId) return '';
        return `${window.location.origin}/#/a/${this.delivery.addressId}/orders/${this.delivery.orderId}`
      }
    }
  }
</script>

<style lang="scss">
.delivery-item-found {
  .v-list-item__content {
    background: #353535;
  }
}
</style>
