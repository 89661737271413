/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsightsInstrumentationKey = process.env.VUE_APP_APP_INSIGHTS_KEY;

if (appInsightsInstrumentationKey != null && appInsightsInstrumentationKey.length > 0) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appInsightsInstrumentationKey
    }
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}


const AppInsightsPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $appInsights() {
          return appInsights;
        }
      },
    });
  },
};

export default AppInsightsPlugin;
