<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card light>
      <v-card-title class="black white--text" style="word-break: break-word;">{{ $t('documents.add_your_own_documents') }}</v-card-title>
      <v-card-text class="scroll-content py-0">
        <div v-if="files && files.length > 0">
          <template v-for="(file, index) in files">
            <v-row :key="file.id" class="my-1">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col :style="{maxWidth: $vuetify.breakpoint.xsOnly ? '44px' : '64px', maxHeight: $vuetify.breakpoint.xsOnly ? '44px' : '64px'}" style="position: relative;" class="rounded">
                    <v-badge left
                             :style="{width: $vuetify.breakpoint.xsOnly ? '44px' : '64px', height: $vuetify.breakpoint.xsOnly ? '44px' : '64px'}"
                             bordered
                             overlap
                             :value="(!loading && file.uploadFailed) || file.uploaded"
                             :color="file.uploadFailed ? 'error' : file.uploaded ? 'success' : ''"
                             :icon="file.uploadFailed ? 'mdi mdi-exclamation-thick' : file.uploaded ? 'mdi mdi-check-bold' : ''"
                             transition="slide-x-transition">
                      <div style="padding: 2px;" class="grey lighten-5">
                        <div v-if="loading && !file.uploaded" style="position: absolute; top: 0; right: 0; left: 0; bottom: 0; background-color: rgba(255,255,255,0.7); z-index: 1;">
                          <v-avatar :size="$vuetify.breakpoint.xsOnly ? 40 : 60" tile>
                            <v-progress-circular
                              :size="$vuetify.breakpoint.xsOnly ? 20 : 30"
                              :width="3"
                              color="accent"
                              indeterminate
                            ></v-progress-circular>
                          </v-avatar>
                        </div>
                        <div v-else-if="file.file.type.indexOf('image') > -1" class="grey lighten-5">
                          <v-img data-cy="image-thumbnail" :src="file.dataUrl" :max-height="$vuetify.breakpoint.xsOnly ? 40 : 60" :max-width="$vuetify.breakpoint.xsOnly ? 40 : 60" aspect-ratio="1" />
                        </div>
                        <div v-else>
                          <v-avatar :size="$vuetify.breakpoint.xsOnly ? 40 : 60" tile color="grey lighten-5">
                            <hb-icon color="primary" :size="$vuetify.breakpoint.xsOnly ? 28 : 38">
                              {{ $getDocumentIcon(file.file.name) }}
                            </hb-icon>
                          </v-avatar>
                        </div>
                      </div>
                    </v-badge>
                  </v-col>
                  <v-col>
                    <div class="ml-4" style="line-height: 1.25rem;">
                      <strong data-cy="file-name" class="accent--text">{{ file.file.name }}</strong>
                      <v-text-field v-model="file.lisatiedot"
                                    data-cy="file-details-field"
                                    class="mt-1"
                                    hide-details
                                    outlined
                                    :disabled="file.uploaded"
                                    dense
                                    :placeholder="$t('documents.anna_lisatietoja')"></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider :key="`divider_${file.id}`" v-if="index < files.length - 1"/>
          </template>
        </div>
        <v-alert v-if="files.findIndex(f => f.uploadFailed) > -1" text type="error">
          <template v-if="files.length > 1">
            {{ $t('errors.error_sending_files') }}
          </template>
          <template v-else>
            {{ $t('errors.error_sending_file') }}
          </template>
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :small="$vuetify.breakpoint.xsOnly" @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn data-cy="save-files-button" :small="$vuetify.breakpoint.xsOnly" :loading="loading" color="primary" @click="saveFiles">
          <template v-if="files.findIndex(f => f.uploadFailed) > -1">
            {{ $t('try_again') }}
          </template>
          <template v-else-if="files.length > 1">
            {{ $t('documents.save_files') }}
          </template>
          <template v-else>
            {{ $t('documents.save_file') }}
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import apiCollection from "@/apicollection";

export default {
  name: "UploadFilesDialog",
  props: ['files', 'tempFiles'],
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('cancel');
      }
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit('cancel');
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async saveFiles() {
      this.loading = true;
      let savePromises = [];
      try {
        for (let i = 0; i < this.files.length; i += 1) {
          const f = this.files[i];
          if (!f.uploaded) {
            if (!f.dataUrl) {
              f.dataUrl = await this.toBase64(f.file);
            }
            try {
              savePromises.push(await this.saveFile(f));
            } catch (e) {
              console.error(e);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      Promise.all(savePromises).then(() => {
        this.$emit('generate-random-string'); // Generoi uuden file inputin, jotta se tyhjenee, jos kaikki tiedostot meni sisälle.
      });
      this.loading = false;
      if (this.files.findIndex(f => f.uploadFailed) === -1) {
        this.$emit('files-saved');
        this.close();
      }
    },
    async saveFile(file) {
      return new Promise(async (resolve, reject) => {
        try {
          let base64EncodedData = file.dataUrl;
          if (file.dataUrl.indexOf('data:') === 0) {
            base64EncodedData = file.dataUrl.split(',', 2)[1];
          }
          const data = {
            lisatiedot: file.lisatiedot,
            tiedosto: base64EncodedData,
            tyyppi: file.type,
            filename: file.file.name,
            filetype: file.file.type,
          };
          const response = await apiCollection.addDocument(data);
          
          this.$showSuccessNotification(this.$t('documents.file_saved', {fileName: file.file.name}), false)
          file.uploadFailed = false;
          file.uploaded = true;
          resolve(response.data);
        } catch (e) {
          console.error(e);
          file.uploadFailed = true;
          file.uploaded = false;
          this.$showErrorNotification(this.$t('documents.error_saving_file', {fileName: file.file.name}), false);
          reject(e);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.scroll-content {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
</style>
