import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import buildStr from "./strbuilder";
import store from "@/store";

export default {
  async getOrders(addressId) {
    if (!addressId) return [];
    const response = await axios.get(`/api/Address/${addressId}/Order`);
    return response.data;
  },
  async getMainDeliveries(addressId) {
    if (!addressId) return [];
    const response = await axios.get(`/api/Address/${addressId}/MainDelivery`);
    return response.data;
  },
  async getSmallDeliveries(addressId) {
    if (!addressId) return [];
    const response = await axios.get(`/api/Address/${addressId}/SmallDelivery`);
    return response.data;
  },
  async getContacts(addressId) {
    if (!addressId) return [];
    const response = await axios.get(`/api/Address/${addressId}/Contact`);
    return response.data;
  },
  async getAllContacts() {
    const response = await axios.get(`/api/Contact`);
    return response.data;
  },
  async getOrder(orderId) {
    const response = await axios.get(`/api/Order/${orderId}`);
    return response.data;
  },
  async getOrderPayments(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/Payment`);
    return response.data;
  },
  async getOrderDeliveries(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/Delivery`);
    return response.data;
  },
  async getOrderReclamations(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/ReclamationRow`);
    return response.data;
  },
  async getOrderProductRows(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/ProductRow`);

    const rows = response.data;

    return buildStr(rows);
  },
  async getOrder3dModels(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/Showroom`);
    const rows = response.data;
    return rows;
  },
  async getOffers(addressId) {
    if (!addressId) return [];
    const response = await axios.get(`/api/Address/${addressId}/Offer`);
    const offers = response.data.map(offer => {
      const copy = Object.assign({}, offer);
      copy.expirationDate = moment(offer.expirationDate);
      return copy;
    });
    return offers;
  },
  async getOffer(offerId, addressId) {
    if (!addressId) return [];
    const response = await axios.get(`/api/Address/${addressId}/Offer`);
    const offers = response.data.map(offer => {
      const copy = Object.assign({}, offer);
      copy.expirationDate = moment(offer.expirationDate);
      return copy;
    });
    return offers.filter(o => o.id === offerId);
  },
  async getOrderWarrantyData(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/WarrantyData`);
    return response.data;
  },
  async getOrderDeliveryData(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/DeliveryData`);
    return response.data;
  },
  async getOrderDocuments(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/Document`);
    return response.data;
  },
  async getOrderBackorders(orderId) {
    const response = await axios.get(`/api/Order/${orderId}/Backorder`);
    return response.data;
  },
  async getOfferDocuments(offerId) {
    const response = await axios.get(`/api/Offer/${offerId}/Document`);
    return response.data;
  },
  async getOffer3dModels(offerId) {
    const response = await axios.get(`/api/Offer/${offerId}/Showroom`);
    return response.data;
  },
  async getAddressDocuments(addressId) {
    const response = await axios.get(`/api/Address/${addressId}/Document`);
    return response.data;
  },
  async getAllDocuments() {
    const response = await axios.get(`/api/Document`);
    return response.data;
  },
  async addDocument(data) {
    const response = await axios.post('/api/Document', data);
    return response.data;
  },
  async getAddresses() {
    const response = await axios.get(`/api/User/Address`);
    const addresses = response.data;
    return addresses;
  },
  async acceptInvitation(invitationId) {
    const response = await axios.get(`/api/Invitation/${invitationId}`);
    return response.data;
  },
  /**
   *
   * @param userId
   * @param {!Object} updateData
   * @param {!string} updateData.language,
   * @param {!boolean} updateData.pushNotificationsEnabled,
   * @returns {Promise<any>}
   */
  async updateUser(userId, updateData) {
    if (!('language' in updateData) || !('pushNotificationsEnabled' in updateData)) {
      console.log('invalid data');
      return;
    }

    const data = {
      language: updateData.language,
      pushNotificationsEnabled: updateData.pushNotificationsEnabled,
    };
    const response = await axios.post(`/api/User`, data);
    return response.data;
  },

  async getNotifications(addressId) {
    const response = await axios.get(`/api/Address/${addressId}/Notification`);
    const notifications = response.data;
    return _.orderBy(notifications, ['createdAt'], ['desc'])
  },
  async openNotification(notificationId) {
    const response = await axios.get(`/api/Notification/${notificationId}`);
    return response.data;
  },
  async getRegistrationLinks() {
    const response = await axios.get('/api/Contact/Registrations');
    return response.data;
  },
  async getInvoiceRows(invoiceId) {
    const response = await axios.get(`/api/Invoice/${invoiceId}/Rows`);
    return response.data;
  },
  async validateImpersonateToken(token) {
    const response = await axios.get(`/api/Impersonate/${token}`)
    return response.data;
  },
  async endImpersonation() {
    const response = await axios.delete(`/api/Impersonate`)
    return response.data;
  }
}
