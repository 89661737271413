import 'babel-polyfill';
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import i18n from './i18n.js';
import vuetify from './plugins/vuetify.plugin.js';
import VueRouter from 'vue-router';
import axios from 'axios';
import auth from './auth';
import user from './user';

import App from './App.vue';
import store from './store';
import routes from './routes';
import ValidationPlugin from './plugins/validation.plugin';
import LocalePlugin from './plugins/locale.plugin';
import DateFormatPlugin from './plugins/dateformat.plugin';
import UserPlugin from './plugins/user.plugin';
import HelperPlugin from './plugins/helper.plugin';
import AppInsightsPlugin from "./plugins/appinsights.plugin";

import BackButton from './components/BackButton.vue';
import MainMenuButton from './components/MainMenuButton.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import FilterDrawer from './components/FilterDrawer.vue';
import IconSharp from './components/IconSharp.vue';
import LanguageSelector from "./components/LanguageSelector.vue";
import SelectedUserAddress from "./components/SelectedUserAddress.vue";

import './registerServiceWorker';
import api from "./apicollection";
import apicollection from './apicollection';

auth.success = initApp;
auth.init();

const vueAppAPIBaseURL = process.env.VUE_APP_API_BASEURL;
const hasCustomBaseUrl = vueAppAPIBaseURL != null && vueAppAPIBaseURL.indexOf('undefined') !== -1 ? false : true;
// In dev env or situations where app is being served apart from API this should be set.
// Current production it is served with API so should use relative (without baseurl)
if (hasCustomBaseUrl) {
  axios.defaults.baseURL = vueAppAPIBaseURL;
}

function tokenInterceptor(auth) {
  axios.interceptors.request.use(config => {
    if (auth.client && auth.client.authenticated) {
      config.headers.Authorization = `Bearer ${auth.accessToken}`;
      config.headers['X-JWT-IDTOKEN'] = `${auth.idToken}`;// TODO: might be needed in /api/UserRole for authorization based on attributes not just email to save some calls
    }
    return config;
  }, error => {
    return Promise.reject(error)
  })
}

function refreshToken() {
  return auth.client.updateToken(120);// do refresh when equal or less than 2 minutes left
}

function accessDeniedInterceptor(auth) {
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response && error.response.status === 401) {
      const isTokenExpired = error.response && error.response.headers['www-authenticate'] && error.response.headers['www-authenticate'].indexOf('token is expired') !== -1;
      if (isTokenExpired) {
        refreshToken();//TODO: return promise? this is far fetched scenario because of refreshToken should be called timely
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  });
}

tokenInterceptor(auth);
accessDeniedInterceptor(auth);

async function initApp(authenticated) {
  const setLanguagePromise = new Promise((resolve, reject) => {
    const keycloakLocaleExists = auth.user.attributes.locale != null && auth.user.attributes.locale.length > 0;
    const userLocaleMissing = (auth.myPuustelliUser?.language == null || auth.myPuustelliUser?.language === "");
    if (userLocaleMissing && keycloakLocaleExists) {
      const keycloakLocale = auth.user.attributes.locale[0];
      api.updateUser(auth.user.id, {
        language: keycloakLocale,
        pushNotificationsEnabled: auth.user.pushNotificationsEnabled,
      }).then((updatedUser) => {
        i18n.locale = keycloakLocale;
        auth.myPuustelliUser = updatedUser;
        resolve();
      })
    } else {
      resolve();
    }
  });
  await setLanguagePromise;

  setInterval(refreshToken, 1000 * 60);

  Vue.config.productionTip = false;

  Vue.use(VueRouter);//at least this must be after auth init, else router fails
  Vue.use(ValidationPlugin);
  Vue.use(LocalePlugin);
  Vue.use(DateFormatPlugin);
  Vue.use(UserPlugin);
  Vue.use(HelperPlugin);
  Vue.use(AppInsightsPlugin);

  user.user = auth.myPuustelliUser;

  if (user.user.impersonateToken) {
    const tokenDetails = await apicollection.validateImpersonateToken(user.user.impersonateToken);
    store.commit('setImpersonateResponse', tokenDetails);
  }


  Vue.component('hb-back-button', BackButton);
  Vue.component('hb-main-menu-button', MainMenuButton);
  Vue.component('hb-loading-indicator', LoadingIndicator);
  Vue.component('hb-filter-drawer', FilterDrawer);
  Vue.component('hb-language-selector', LanguageSelector);
  Vue.component('hb-icon', IconSharp);
  Vue.component('hb-selected-address', SelectedUserAddress);

  const router = new VueRouter({routes});

  router.beforeEach((to, from, next) => {
    const impersonate = store.getters.getImpersonateObject;

    if (impersonate) {
      const now = new Date().getTime();
      const impersonationEndsAt = new Date(impersonate.voimassaasti).getTime();

      if (now > impersonationEndsAt && to.name !== 'ImpersonationEnded') {
        next({ name: 'ImpersonationEnded' });
        return;
      }
    }
    next();
  });

  const vue = new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
  });

  vue.$mount('#app');
}

function tokenRefresh() {

}
