import DashboardView from './views/DashboardView.vue';
import OrdersView from './views/OrdersView.vue';
import OffersView from './views/OffersView.vue';
import SingleOrderView from './views/SingleOrderView.vue';
import SingleOfferView from './views/SingleOfferView.vue';
import ContactsView from "./views/ContactsView.vue";
import InvitationView from './views/InvitationView.vue';
import DocumentsView from './views/DocumentsView.vue';
import WarrantyCertificateView from "./views/WarrantyCertificateView.vue";
import DeliveryPreparationView from "./views/DeliveryPreparationView.vue";
import UserSettingsView from "./views/UserSettingsView.vue";
import AddressView from './views/AddressView.vue';
import DefaultView from "./views/DefaultView.vue";
import NotificationView from "./views/NotificationView";
import SingleBackOrderView from "@/views/SingleBackOrderView";
import DeliveriesView from "@/views/DeliveriesView";
import Webshop from "@/views/Webshop";
import ImpersonateLandingView from "@/views/ImpersonateLandingView.vue";
import ImpersonateEndedView from "@/views/ImpersonateEndedView.vue";

export default [
  {
    path: '/a/:addressId',
    name: 'address',
    component: AddressView,
    props: true,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        props: true,
        component: DashboardView,
        meta: { addressChangeRedirect: 'dashboard' },
      },
      {
        path: 'orders',
        name: 'orders',
        props: true,
        meta: { addressChangeRedirect: 'orders' },
        component: OrdersView,
      },
      {
        path: 'orders/:id',
        name: 'singleorder',
        component: SingleOrderView,
        meta: { addressChangeRedirect: 'orders' },
        props: true,
      },
      {
        path: 'orders/backorder/:id',
        name: 'singlebackorder',
        component: SingleBackOrderView,
        meta: { addressChangeRedirect: 'orders' },
        props: true,
      },
      {
        path: 'orders/:id/warranty',
        name: 'warrantycertificate',
        component: WarrantyCertificateView,
        meta: { addressChangeRedirect: 'orders' },
        props: true,
      },
      {
        path: 'orders/:id/deliverypreparation',
        name: 'deliverypreparation',
        component: DeliveryPreparationView,
        meta: { addressChangeRedirect: 'orders' },
        props: true,
      },
      {
        path: 'offers',
        name: 'offers',
        component: OffersView,
        meta: { addressChangeRedirect: 'offers' },
        props: true,
      },
      {
        path: 'offers/:id',
        name: 'singleoffer',
        component: SingleOfferView,
        meta: { addressChangeRedirect: 'offers' },
        props: true,
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: ContactsView,
        meta: { addressChangeRedirect: 'contacts' },
        props: true,
      },
      {
        path: 'documents',
        name: 'documents',
        component: DocumentsView,
        meta: { addressChangeRedirect: 'documents' },
        props: true,
      },
      {
        path: 'deliveries',
        name: 'deliveries',
        component: DeliveriesView,
        meta: { addressChangeRedirect: 'deliveries' },
        props: true,
      },
      {
        path: '*',
        redirect: 'dashboard',
      }
    ]
  },
  {
    path: '/invitation/:id',
    name: 'invitation',
    component: InvitationView,
    props: true,
  },
  {
    path: '/',
    name: 'default',
    component: DefaultView,
  },
  {
    path: '/n/:notificationId',
    name: 'notification_redirect',
    props: true,
    component: NotificationView,
  },
  {
    path: '/settings',
    name: 'settings',
    component: UserSettingsView,
  },
  {
    path: '/webshop',
    name: 'webshop',
    component: Webshop,
  },
  {
    path: '/impersonate/:token',
    name: 'impersonate',
    component: ImpersonateLandingView
  },
  {
    path:'/impersonation-ended',
    name: 'ImpersonationEnded',
    component: ImpersonateEndedView
  },
  { path: '*', redirect: '/' },
];
