<template>
  <div v-if="offer">

    <v-list-item  @click="goToOffer(offer)">
      <v-list-item-content>

        <!-- SM AND UP -->
        <v-container pa-0 fluid v-if="$vuetify.breakpoint.smAndUp">
          <v-row no-gutters>
            <v-col sm="7" class="font-weight" :class="bold ? 'font-weight-bold' : ''">
              <div :class="indent ? 'ml-3' : ''">{{ $tc('offers.title', 1) }} #{{ offer.id }}</div>
              <div :class="indent ? 'ml-3' : ''" class="caption grey--text text--lighten-1 mr-1"><template v-if="offer.info != null">{{ offer.info }}</template></div>
            </v-col>
            <v-col sm="5" class="caption grey--text darken-1">
              {{ $formatDateNoTime(offer.expirationDate) }}
            </v-col>
          </v-row>
        </v-container>

        <!-- XS ONLY -->
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-list-item-title class="" :class="bold ? 'font-weight-bold' : ''">
            <div :class="indent ? 'ml-2' : ''">{{ $tc('offers.title', 1) }} #{{ offer.id }}<template v-if="offer.info != null"></template></div>
          </v-list-item-title>
          <v-list-item-title v-if="offer.info != null" class="caption grey--text text--lighten-2 text-wrap">
            {{ $t('orders.info') }}: {{ offer.info }}
          </v-list-item-title>
          <v-list-item-subtitle class="caption grey--text darken-1">
            <div :class="indent ? 'ml-2' : ''">{{ $t('offers.valid_until_date') }}: {{ $formatDateNoTime(offer.expirationDate) }}</div>
          </v-list-item-subtitle>
        </template>

      </v-list-item-content>

    </v-list-item>

  </div>
</template>

<script>
  import api from '../../apicollection';

  export default {
    name: "OfferListItem",
    props: {
      offer: {
        type: [Object, Boolean],
        default: false,
      },
      indent: {
        type: Boolean,
        default: false,
      },
      bold: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      async openAttachments() {
        const documents = await api.getOfferDocuments(this.offer.id);
      },
      goToOffer(offer) {
        this.$router.push({ name: 'singleoffer', params: { id: offer.id }})
      }
    }
  }
</script>

<style scoped>

</style>
