<template>
  <div>
    <v-list two-line style="background: transparent;" data-cy="contacts_register_links_list">

      <template v-for="(links, key) in registerLinksGroupedByCountry">
        <v-subheader :key="key" class="font-weight-medium"><hb-icon color="primary" left>app_registration</hb-icon> {{ key }}</v-subheader>

        <v-list-item v-for="link in links" :key="link.url" :href="link.url" target="_blank">
          <v-list-item-content>
            <v-list-item-title>{{ link.manufacturer }}</v-list-item-title>
            <v-list-item-subtitle class="caption grey--text darken-1">{{ link.url }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </template>

    </v-list>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: "RegisterLinksList",
  props: {
    registerLinks: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {}
  },
  methods: {
  },
  computed: {
    registerLinksGroupedByCountry() {
      return _.groupBy(this.registerLinks, 'country');
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.icon-text {
  font-size: 24px;
  color: #D30A10;
  padding-left: 2px;
  line-height: 20px;
}
</style>
