<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid :fill-height="loading" class="warranty">

    <v-app-bar color="black" dark fixed app>

      <hb-back-button fallback="orders" />

      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $t('warranty.warranty_certificate') }}<span class="grey--text lighten-5">{{ warranty && warranty.orderId ? ` #${warranty.orderId}` : ''}}</span></v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading">
        <v-col cols="12" xl="8" offset-xl="2" class="warranty-holder">

          <v-row>

            <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" lg="6" offset-lg="3" xl="6" offset-xl="3" class="pb-0">
              <v-row justify="center">
                <v-col v-if="warranty.warrantyType === 'KULUTTAJA'" cols="4"><v-img :src="warranty10" alt="Takuu 10v"></v-img></v-col>
                <v-col v-if="warranty.warrantyType === 'KULUTTAJA'" cols="4"><v-img :src="warranty30" alt="Takuu 30v"></v-img></v-col>
                <v-col v-if="warranty.warrantyType === 'PGPRO'" cols="4"><v-img :src="warrantypgpro5" alt="Takuu 5v"></v-img></v-col>
                <v-col v-if="warranty.warrantyType === 'PGPRO'" cols="4"><v-img :src="warrantypgpro10" alt="Takuu 10v"></v-img></v-col>
                <v-col v-if="warranty.warrantyType !== 'KULUTTAJA' && warranty.warrantyType !== 'PGPRO'" cols="4"><v-img :src="warranty2" alt="Takuu 2v"></v-img></v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-card outlined color="#191919">
                <v-card-title>
                  <div>
                    <div v-if="warranty.warrantyType === 'PGPRO'" class="headline">{{ $t('warranty.warranty_pg_pro.title') }}</div>
                    <div class="display-1">{{ $t('warranty.warranty_certificate') }}</div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <div class="body-1">
                    <template v-if="warranty.warrantyType === 'KULUTTAJA'">
                      {{ $t(`warranty.warranty_10_30.introduction`) }}
                    </template>
                    <template v-if="warranty.warrantyType === 'PGPRO'">
                      {{ $t(`warranty.warranty_pg_pro.introduction`) }}
                    </template>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="5">
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card outlined color="#191919">
                    <v-card-title>{{ $t('warranty.warranty_details') }}</v-card-title>
                    <v-card-text>

                      <div class="subtitle-2 white--text">{{ $t('customer.title') }}</div>
                      <div class="mb-2">{{ warranty.customerName }}</div>

                      <div class="subtitle-2 white--text">{{ $t('customer.delivery_address') }}</div>
                      <div class="mb-2">{{ warranty.address1 }}, {{ warranty.address2 }}</div>

                      <div class="subtitle-2 white--text">{{ $t('orders.order_number') }}</div>
                      <div class="mb-2">{{ warranty.orderId }}</div>

                      <div class="subtitle-2 white--text">{{ $t('orders.delivery_date') }}</div>
                      <div class="mb-2">{{ warranty.deliveryDate }}</div>

                      <div class="subtitle-2 white--text">{{ $t('orders.store') }}</div>
                      <div class="mb-2">{{ warranty.storeName }}</div>

                      <div class="subtitle-2 white--text">{{ $t('orders.sales_person') }}</div>
                      <div class="mb-2">{{ warranty.salesPerson }}</div>

                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>
            </v-col>

            <v-col cols="12" md="7" class="pt-0">
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919">
                    <v-card-title>{{ $t('warranty.warranty_terms') }}</v-card-title>
                    <v-card-text>

                      <div class="subtitle-2 white--text">{{ $t('warranty.start') }}</div>
                      <div class="mb-2">
                        <template v-if="warranty.warrantyType === 'KULUTTAJA'">
                          <p>{{ $t('warranty.warranty_10_30.start') }}</p>
                        </template>
                        <template v-if="warranty.warrantyType === 'PGPRO'">
                          <p>{{ $t('warranty.warranty_pg_pro.start') }}</p>
                        </template>
                      </div>

                      <div class="subtitle-2 white--text">{{ $t('warranty.content') }}</div>
                      <div class="mb-3">
                        <template v-if="warranty.warrantyType === 'KULUTTAJA'">
                          <ul>
                            <li>{{ $t('warranty.warranty_10_30.content.1') }}</li>
                            <li>{{ $t('warranty.warranty_10_30.content.2') }}</li>
                          </ul>
                        </template>
                        <template v-if="warranty.warrantyType === 'PGPRO'">
                          <ul>
                            <li>{{ $t('warranty.warranty_pg_pro.content.1') }}</li>
                            <li>{{ $t('warranty.warranty_pg_pro.content.2') }}</li>
                          </ul>
                        </template>
                      </div>

                      <div class="subtitle-2 white--text">{{ $t('warranty.exclude') }}</div>
                      <div class="mb-2">
                        <template v-if="warranty.warrantyType === 'KULUTTAJA'">
                          <p>{{ $t('warranty.warranty_10_30.exclude') }}</p>
                        </template>
                        <template v-if="warranty.warrantyType === 'PGPRO'">
                          <p>{{ $t('warranty.warranty_pg_pro.exclude') }}</p>
                        </template>
                      </div>

                      <div class="subtitle-2 white--text">{{ $t('warranty.validity') }}</div>
                      <div class="mb-2">
                        <template v-if="warranty.warrantyType === 'KULUTTAJA'">
                          <p>{{ $t('warranty.warranty_10_30.validity') }}</p>
                        </template>
                        <template v-if="warranty.warrantyType === 'PGPRO'">
                          <p>{{ $t('warranty.warranty_pg_pro.validity') }}</p>
                        </template>
                      </div>

                      <div class="subtitle-2 white--text">{{ $t('warranty.procedure') }}</div>
                      <div class="mb-2">
                        <template v-if="warranty.warrantyType === 'KULUTTAJA'">
                          <p>{{ $t('warranty.warranty_10_30.procedure.1') }}</p>
                          <p>{{ $t('warranty.warranty_10_30.procedure.2') }}</p>
                          <p>{{ $t('warranty.warranty_10_30.procedure.3') }}</p>
                        </template>
                        <template v-if="warranty.warrantyType === 'PGPRO'">
                          <p>{{ $t('warranty.warranty_pg_pro.procedure.1') }}</p>
                          <p>{{ $t('warranty.warranty_pg_pro.procedure.2') }}</p>
                          <p>{{ $t('warranty.warranty_pg_pro.procedure.3') }}</p>
                        </template>
                      </div>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-col>
      </v-row>
    </v-fade-transition>


  </v-container>
</template>

<script>
  import api from '../apicollection';

  export default {
    name: "WarrantyCertificateView",
    props: {
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
        warranty: null,
      }
    },
    computed: {
      warranty2() {
        if (this.$user.language === 'sv')
          return '/img/Puustelli_garanti_2.png';

        return '/img/Puustelli_takuu_2.png';
      },
      warranty10() {
        if (this.$user.language === 'sv')
          return '/img/Puustelli_garanti_10.png';

        return '/img/Puustelli_takuu_10.png';
      },
      warranty30() {
        if (this.$user.language === 'sv')
          return '/img/Puustelli_garanti_30.png';

        return '/img/Puustelli_takuu_30.png';
      },
      warrantypgpro5() {
        if (this.$user.language === 'sv')
          return '/img/PG_Pro_takuu_5.png';

        return '/img/PG_Pro_takuu_5.png';
      },
      warrantypgpro10() {
        if (this.$user.language === 'sv')
          return '/img/PG_Pro_takuu_10.png';

        return '/img/PG_Pro_takuu_10.png';
      },
    },
    watch: {
      id(newValue, oldValue) {
        this.reloadOrder(newValue);
      }
    },
    methods: {
      async reloadWarrantyData() {
        this.loading = true;
        try {
          const warranty = await api.getOrderWarrantyData(this.id);
          this.warranty = warranty;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_warranty'), true, this.reloadWarrantyData);
        }
        this.loading = false;
      },
    },
    mounted() {
      this.loading = false;
      this.$gaPageView();
      this.reloadWarrantyData();

      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      });
    },
  }
</script>

<style lang="scss" scoped>
</style>
