<template>
  <div>
    <v-list three-line style="background: transparent;" data-cy="contacts_list">

      <v-list-item v-for="(contact, index) in ownContacts" :key="contact.name">
        <v-list-item-icon>
          <hb-icon color="primary" v-if="index === 0">star</hb-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ contact.name }}</v-list-item-title>
          <v-list-item-subtitle class="caption grey--text darken-1">{{ contact.title }}{{ contact.company ? ", " + contact.company : '' }}</v-list-item-subtitle>
          <v-list-item-subtitle class="caption grey--text darken-1">{{ contact.phone }}</v-list-item-subtitle>
          <v-list-item-subtitle class="caption grey--text darken-1">{{ contact.email }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn :data-cy="`contact_own_contacts_item_${index}`" v-on="on" icon><hb-icon>more_vert</hb-icon></v-btn>
            </template>
            <v-list dense class="pa-0">
              <v-list-item v-if="contact.phone != null" :href="`tel:${contact.phone}`">
                <v-list-item-avatar><v-icon>call</v-icon></v-list-item-avatar>
                <v-list-item-title>{{ $t('contacts.call') }}</v-list-item-title>
              </v-list-item>
              <v-divider v-if="contact.phone != null && contact.email != null"></v-divider>
              <v-list-item v-if="contact.email != null" :href="`mailto:${contact.email}`">
                <v-list-item-avatar><v-icon>email</v-icon></v-list-item-avatar>
                <v-list-item-title>{{ $t('contacts.send_email') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <template v-for="(character, allContactIndex) in allContacts">
        <div :key="character.character">
          <v-list-item v-for="(contact, index) in character.contacts" :key="contact.name">
            <v-list-item-icon>
              <span class="icon-text text-uppercase" v-if="index === 0 && !contact.showLogo">{{ character.character }}</span>
              <svg v-if="contact.showLogo === 'op'" aria-hidden="false" role="img" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" height="24" data-cy="op-logo">
                <path d="M25 0a25 25 0 1 0 0 50 25 25 0 1 0 0-50z" fill="#ff6a10"></path>
                <path d="M27.5 44.5h-5V34.2c-1.7 1.1-3.8 1.8-6 1.8-6.1 0-11-4.9-11-11s4.9-11 11-11c2.2 0 4.3.7 6 1.8V5.5h5v10.3c1.7-1.1 3.8-1.8 6-1.8 6.1 0 11 4.9 11 11s-4.9 11-11 11c-2.2 0-4.3-.7-6-1.8v10.3zm6-25.5c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm-17 0c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" fill="#fff"></path>
              </svg>

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ contact.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="contact.title || contact.company" class="caption grey--text darken-1">{{ contact.title }}{{ contact.company ? ", " + contact.company : '' }}</v-list-item-subtitle>
              <v-list-item-subtitle class="caption grey--text darken-1">{{ contact.phone }}</v-list-item-subtitle>
              <v-list-item-subtitle class="caption grey--text darken-1">{{ contact.email }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="contact.info" class="caption grey--text darken-1">{{ contact.info }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon :data-cy="`contact_all_contacts_item_${allContactIndex}`"><hb-icon>more_vert</hb-icon></v-btn>
                </template>
                <v-list dense class="pa-0" :data-cy="`contact_email_call_menu_${allContactIndex}`">
                  <v-list-item v-if="contact.phone != null" :href="`tel:${contact.phone}`" :data-cy="`contact_phone_${allContactIndex}`">
                    <v-list-item-avatar><v-icon>call</v-icon></v-list-item-avatar>
                    <v-list-item-title>{{ $t('contacts.call') }}</v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="contact.phone != null && contact.email != null"></v-divider>
                  <v-list-item v-if="contact.email != null" :href="`mailto:${contact.email}`" :data-cy="`contact_email_${allContactIndex}`">
                    <v-list-item-avatar><v-icon>email</v-icon></v-list-item-avatar>
                    <v-list-item-title>{{ $t('contacts.send_email') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </div>
</template>

<script>
  export default {
    name: "ContactsList",
    props: {
      contacts: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {}
    },
    methods: {
      log(data) {
        console.log(data);
      }
    },
    computed: {
      ownContacts() {
        return this.contacts.filter(c => c.ownContact);
      },
      allContacts() {
        const contacts = this.contacts.filter(c => !c.ownContact);
        contacts.sort((a,b) => {
          return b.name - a.name;
        });
        const contactsByCharacter = [];
        contacts.forEach(contact => {
          const character = contact.name.charAt(0).toLowerCase();
          const characterIndex = contactsByCharacter.findIndex(l => l.character === character);
          if (characterIndex > -1) {
            contactsByCharacter[characterIndex].contacts.push(contact);
          } else {
            contactsByCharacter.push({
              character: character,
              contacts: [contact],
            });
          }
        });
        return contactsByCharacter;
      },
    },
    mounted() {
    },
  }
</script>

<style lang="scss" scoped>
  .icon-text {
    font-size: 24px;
    color: #D30A10;
    padding-left: 2px;
    line-height: 20px;
  }
</style>
