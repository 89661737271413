import Vue from 'vue';
import i18n from './i18n'

const userComp = new Vue({
  data() {
    return {
      user: null
    }
  },
  watch: {
    user(newValue, oldValue) {
      i18n.locale = newValue.language;
    }
  },
});

export default userComp;
