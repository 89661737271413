import { render, staticRenderFns } from "./Webshop.vue?vue&type=template&id=72482be8&scoped=true&xmlns%3Av-touch=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./Webshop.vue?vue&type=script&lang=js&"
export * from "./Webshop.vue?vue&type=script&lang=js&"
import style0 from "./Webshop.vue?vue&type=style&index=0&id=72482be8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72482be8",
  null
  
)

export default component.exports