<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    props: {
      addressId: {
        type: [Number, String],
      },
    },
    watch: {
      addressId(newValue) {
        this.$store.commit('setSelectedAddress', Number.parseInt(newValue));
      }
    },
    computed: {
      addresses() {
        if (this.$store.state.userProjects == null) {
          return [];
        }
        return this.$store.state.userProjects;
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
