<template>
  <v-container pa-0 fluid :fill-height="loading" class="orders">

    <v-app-bar color="black" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          :placeholder="`${$t('orders.search_for_orders')}...`"
          v-model="searchPhrase"
          class="title text-uppercase topbar-search"
          style="font-size: 20px;"
          single-line
          date
          full-width
          hide-details
          color="grey"
        >
          <hb-icon style="cursor: pointer;" slot="append">search</hb-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension v-if="$vuetify.breakpoint.mdAndDown && $userHasMultipleAddresses">
        <hb-selected-address />
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition hide-on-leave>
      <v-row no-gutters v-if="!loading">
        <v-alert v-if="$userHasMultipleAddresses" class="ma-4" outlined text type="info">
          {{ $t('orders.multiple_addresses_reminder') }}
        </v-alert>
        <v-col cols="12" no-gutters class="orders-holder">
          <i18n path="orders.deliveries_link" tag="p" class="px-4 pt-4">
            <template v-slot:url>
              <a class="accent--text" @click="$router.push('deliveries')">{{ $tc('deliveries.title',0) }}</a>
            </template>
          </i18n>

          <v-list v-if="orders && orders.length > 0" color="transparent" :three-line="$vuetify.breakpoint.xsOnly" data-cy="orders_list">

            <v-list-item v-if="$vuetify.breakpoint.smAndUp">
              <v-list-item-content>
                <v-container pa-0 fluid>
                  <v-row no-gutters>
                    <v-col sm="3" class="caption grey--text">
                      {{ $t('orders.order_number') }}
                    </v-col>
                    <v-col sm="3" class="caption grey--text">
                      {{ $t('orders.info') }}
                    </v-col>
                    <v-col sm="3" class="caption grey--text">
                      {{ $t('orders.delivery_date') }}
                    </v-col>
                    <v-col sm="3" class="caption grey--text">
                      {{ $t('orders.order_status') }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>

            <template v-if="orders.length > 0">
              <v-subheader class="font-weight-medium"><hb-icon color="primary" left>assignment_turned_in</hb-icon> {{ $t('orders.active_orders') }}</v-subheader>

              <template v-if="filteredOrders.length > 0">
                <template v-for="order in filteredOrders">
                  <order-list-item :key="order.id" :order="order" bold />

                  <template v-for="subOrder in order.orderRows">
                    <order-list-item v-if="subOrder.id.includes(searchPhrase.toLowerCase())"
                                     :found="searchPhrase.length > 0 && subOrder.id.includes(searchPhrase.toLowerCase())"
                                     :key="subOrder.id"
                                     :order="subOrder"
                                     indent
                                     :bold="false" />
                  </template>

                  <template v-if="order.containsBackorders">
                    <order-list-item v-if="order.id.includes(searchPhrase.toLowerCase())"
                                     :found="searchPhrase.length > 0 && order.id.includes(searchPhrase.toLowerCase())"
                                     :key="`backorder_${order.id}`"
                                     :backorder="true"
                                     :order="order"
                                     indent
                                     :bold="false" />
                  </template>
                </template>
              </template>
              <template v-else>
                <v-alert class="mx-3" type="info" text>{{ $t('orders.couldnt_find_any_orders') }}</v-alert>
              </template>

            </template>

          </v-list>

          <template v-if="orders && orders.length === 0 && !loading">
            <v-col cols="12">
              <v-row justify="center" align="center" no-gutters>
                <div class="text-center pl-5 pr-5" style="padding-bottom: 150px;" :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px'}">
                  <hb-icon size="100" color="grey darken-3">assignment_turned_in</hb-icon>
                  <div class="headline font-weight-bold mt-3 mb-3">{{ $t('orders.no_orders') }}</div>
                  <div class="subtitle-1 grey--text text--lighten-2">{{ $t('orders.no_orders_help') }}.</div>
                  <v-btn @click="$reloadApp" class="mt-5" outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
                </div>
              </v-row>
            </v-col>
          </template>

        </v-col>
      </v-row>
    </v-fade-transition>

  </v-container>
</template>

<script>
  import OrderListItem from "../components/OrdersView/OrderListItem.vue";
  import api from '../apicollection';

  export default {
    name: "OrdersView",
    components: {
      OrderListItem: OrderListItem,
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
        orders: [],
      }
    },
    watch: {
      selectedAddress(newValue, oldValue) {
        this.reloadOrders();
      }
    },
    methods: {
      async reloadOrders() {
        if (!this.selectedAddress) {
          this.loading = false;
          return;
        }

        this.loading = true;
        try {
          const orders = await api.getOrders(this.selectedAddress);
          this.orders = orders;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_orders'), true, this.reloadOrders);
        }
        this.loading = false;
      },
    },
    computed: {
      selectedAddress() {
        return this.$store.state.selectedAddress;
      },
      filteredOrders() {
        const searchPhrase = this.searchPhrase.toLowerCase();
        return this.orders.filter((d) =>
          d.id.toLowerCase().includes(searchPhrase) ||
          d.orderRows.map(or => or.id.toLowerCase()).join(' ').includes(searchPhrase)
        );
      },
    },
    mounted() {
      this.$gaPageView();
      this.reloadOrders();
    }
  }
</script>

<style lang="scss" scoped>
</style>
