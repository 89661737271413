<template>
  <v-app id="app" dark :style="{ background: $vuetify.theme.themes[theme].background }">
    <template v-if="$store.state.showFullPage && !loading">
      <v-snackbar v-model="snackbar" top multi-line :timeout="timeOut" :color="color">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            v-if="showButton"
            small
            outlined
            @click="
              () => {
                callback();
                snackbar = false;
              }
            "
          >
            {{ buttonText }}
          </v-btn>
          <v-btn icon v-bind="attrs" @click="snackbar = false">
            <hb-icon>close</hb-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-btn
        style="
          position: fixed;
          top: 22px;
          z-index: 7;
          background-color: #222;
          transition: left 0.2s ease-in-out;
        "
        :style="{ left: $vuetify.breakpoint.lgAndUp ? (miniMenu ? '69px' : '289px') : '-99999px' }"
        icon
        outlined
        x-small
        dark
        color="#777"
        @click.stop="toggleMiniMenu"
      >
        <hb-icon v-if="miniMenu">chevron_right</hb-icon>
        <hb-icon v-else>chevron_left</hb-icon>
      </v-btn>

      <v-navigation-drawer
        v-model="menuOpenState"
        :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
        mini-variant-width="80"
        app
        fixed
        light
        width="300"
      >
        <v-list dark color="black" class="pa-0" style="border-radius: 0">
          <v-list-item style="padding: 2px 10px; border-right: 1px solid #777">
            <v-list-item-avatar
              @click.stop="toggleMiniMenu"
              style="margin-top: 10px; margin-bottom: 10px; overflow: hidden"
            >
              <v-img src="/img/puustelli_black_p.svg" width="40"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subheading">{{ userFullName }}</v-list-item-title>
              <v-list-item-subtitle class="caption">{{ userEmail }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list two-line dark class="pa-0 primary" style="border-radius: 0;">
          <v-list-item v-if="$isImpersonating" style="padding: 2px 10px;">
            <v-list-item-avatar @click.stop=""
                                style="margin-top: 10px; margin-bottom: 10px; overflow: hidden;">
              <v-icon>mdi mdi-glasses</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subheading">{{impersonateData.etunimi}} {{impersonateData.sukunimi}}</v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ $t('impersonate.menu_subtitle', {time: $formatTimeNoDate(impersonateData.voimassaasti)})}}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-on="on"
                         v-bind="attrs"
                         icon
                         small
                         @click.stop="endImpersonation()">
                    <v-icon small>logout</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('impersonate.end')}}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-if="userHasMultipleActiveAddresses">
          <v-divider></v-divider>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selectedAddress"
                  :items="projects"
                  outlined
                  :label="$t('address')"
                  :placeholder="$t('all_addresses')"
                  hide-details
                  item-text="streetAddress"
                  item-value="id"
                  class="address-select"
                  data-cy="address-select"
                >
                  <template v-slot:selection="{ item }">
                    <div style="max-width: 90%" class="text-truncate body-2">
                      {{ item.streetAddress }}
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>

          <v-divider></v-divider>
        </template>

        <v-list :dense="$vuetify.breakpoint.mdAndDown">
          <template v-if="filteredPrimaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredPrimaryPages"
              :key="index + 'primary'"
              :to="getPageLink(page)"
              :data-cy="page.dataCy"
              active-class="primary--text"
            >
              <v-list-item-action>
                <hb-icon>{{ page.icon }}</hb-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title class="body-2 font-weight-bold">{{
                    page.title
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="filteredSecondaryPages.length > 0">
            <template v-for="(page, index) in filteredSecondaryPages">
              <v-list-item
                :key="index + 'secondary'"
                :to="getPageLink(page)"
                :value="$route.name === page.link"
                :data-cy="page.dataCy"
                active-class="primary--text"
              >
                <v-list-item-action>
                  <hb-icon>{{ page.icon }}</hb-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="body-2 font-weight-bold">{{
                      page.title
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>

          <v-divider></v-divider>

          <hb-language-selector/>

          <v-divider></v-divider>

          <v-list-item
            v-if="!$isImpersonating"
            :to="{ name: 'settings' }"
            :value="$route.name === 'settings'"
            data-cy="settings"
            exact
            active-class="primary--text"
          >
            <v-list-item-action>
              <v-icon>settings_applications</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">{{
                  $t("user_settings.title")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!$isImpersonating" @click="logout" data-cy="logout">
            <v-list-item-action>
              <v-icon>logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">{{
                  $t("logout")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div
          v-if="showHash"
          class="grey--text text--darken-1"
          style="position: absolute; left: 20px; right: 20px; bottom: 10px"
        >
          Revision: #{{ hash }}
        </div>
      </v-navigation-drawer>

      <v-bottom-navigation
        v-if="$vuetify.breakpoint.mdAndDown"
        :value="bottomNavSelected"
        :height="isIphone ? 70 : 56"
        app
        light
        grow
        color="white"
      >
        <template v-for="(page, index) in filteredPrimaryPages">
          <v-btn
            :class="isIphone ? 'pb-1' : ''"
            class="px-1"
            style="max-width: 86px; min-width: 64px;"
            :key="index"
            color="primary"
            text
            :value="page.link"
            @click="goToLink(page.link)"
          >
            <span :style="{ fontSize: $vuetify.breakpoint.xsOnly ? '10px' : '' }">{{
                page.title
              }}</span>
            <hb-icon>{{ page.icon }}</hb-icon>
          </v-btn>
        </template>
      </v-bottom-navigation>
    </template>

    <v-main v-if="!loading">
      <router-view class="fill-height"/>
    </v-main>
  </v-app>
</template>

<script>
import auth from "./auth";
import dayjs, {Dayjs} from "dayjs";
import apicollection from "@/apicollection";

export default {
  data() {
    return {
      color: "",
      text: "",
      buttonText: "",
      showButton: false,
      timeOut: -1,
      callback: () => {
      },
      snackbar: false,
      bottomNavSelected: "",
      showBottomNav: true,
      menuOpenState: false,
      selectedAddress: 0,
      loading: true,
    };
  },
  methods: {
    logout() {
      auth.client.logout();
    },
    endImpersonation() {
      // try {
      //   await apicollection.endImpersonation();
      //   const rootURL = window.location.origin;
      //   window.location.assign(rootURL)
      // } catch (error) {
      //   console.log(error)
      // }
      this.$router.push({name: 'ImpersonationEnded'});
    },
    getPageLink(page) {
      const link = {
        name: page.link,
      };

      if ("params" in page) {
        link.params = page.params;
      }
      return link;
    },
    goToLink(name) {
      this.$router.push({name: name});
    },
    toggleMiniMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.$store.commit("toggleMiniMenu");
      } else {
        this.$store.commit("toggleMainMenuState");
      }
    },
    handleSelectedBottomNavItem(route) {
      const name = route.name;
      const isPagePrimary = this.pages.findIndex((p) => p.primary && p.link === name) > -1;
      if (isPagePrimary) {
        this.bottomNavSelected = name;
        this.showBottomNav = true;
      } else {
        this.showBottomNav = false;
      }
    },
    async reloadProjects() {
      this.loading = true;
      try {
        await this.$store.dispatch("reloadProjects");
        if (this.$store.state.selectedAddress) {
          this.selectedAddress = this.$store.state.selectedAddress;
        }
      } catch (error) {
        console.log("error", error);
      }
      this.loading = false;
    },
  },
  watch: {
    storeAddress(to) {
      if (to !== this.selectedAddress) {
        this.selectedAddress = to;
      }
    },
    notificationObject(to) {
      this.color = to.color;
      this.text = to.message;
      this.snackbar = true;
      this.buttonText = to.buttonText;
      this.showButton = to.showButton;
      this.timeOut = to.timeOut;
      this.callback = to.callback;
    },
    menuOpenComputed(to) {
      this.menuOpenState = to;
    },
    menuOpenState(to) {
      if (to !== this.$store.state.mainMenuOpen) {
        this.$store.commit("toggleMainMenuState");
      }
    },
    $route(to, from) {
      // Dont load addresses if invitation page is shown, only when navigate away from it
      if (from.name === "invitation" && to.name !== "invitation") {
        this.reloadProjects();
        return;
      }

      this.handleSelectedBottomNavItem(to);

      if (from && from.name) this.$store.commit("setPreviousRouteName", from.name);
    },
    selectedAddress(to, from) {
      this.$store.commit("setSelectedAddress", to);

      const currentRoute = this.$router.currentRoute;

      const currentRouteName = currentRoute.name;
      const currentRoutePath = currentRoute.path;
      const currentParams = currentRoute.params;

      if ("addressId" in currentParams && currentParams.addressId !== `${to}`) {
        const params = Object.assign({}, currentParams);
        params.addressId = to;
        if ("addressChangeRedirect" in currentRoute.meta) {
          const target = currentRoute.meta.addressChangeRedirect;
          this.$router.push({name: target, params});
        } else {
          this.$router.push({name: "dashboard", params});
        }
      }
    },
  },
  computed: {
    storeAddress() {
      return this.$store.state.selectedAddress;
    },
    impersonateData() {
      if (this.$store.state.impersonate === {}) {
        return null;
      }
      return this.$store.getters.getImpersonateObject;
    },
    isIphone() {
      return /iPhone/i.test(navigator.userAgent) || /iPhone/i.test(navigator.platform);
    },
    miniMenu() {
      return this.$store.state.miniMenu;
    },
    userHasNoAddresses() {
      return this.$store.state.userProjects != null && this.$store.state.userProjects.length === 0;
    },
    pages() {
      return [
        {
          title: this.$t("dashboard.title"),
          icon: "home",
          dataCy: "dashboard",
          link: "dashboard",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: true,
        },
        {
          title: this.$tc("offers.title", 2),
          icon: "assignment",
          dataCy: "offers",
          link: "offers",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: true,
        },
        {
          title: this.$tc("orders.title", 2),
          icon: "assignment_turned_in",
          dataCy: "orders",
          link: "orders",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: true,
        },
        {
          title: this.$tc("deliveries.title", 2),
          icon: "mdi-truck",
          dataCy: "deliveries",
          link: "deliveries",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: true,
        },
        {
          title: this.$tc("documents.title", 2),
          icon: "insert_drive_file",
          dataCy: "documents",
          link: "documents",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: true,
        },
        {
          title: this.$t("contacts.title"),
          icon: "chat",
          dataCy: "contacts",
          link: "contacts",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: false,
        },
        {
          title: this.$t("webshop.title"),
          icon: "mdi-shopping",
          dataCy: "webshop",
          link: "webshop",
          params: {},
          roles: ["mypuustelli_ADMIN", ""],
          primary: false,
        },
      ];
    },
    projects() {
      return this.$store.state.userProjects;
    },
    userHasMultipleActiveAddresses() {
      return this.projects != null && this.projects.length > 1;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    showHash() {
      return !(this.miniMenu && this.$vuetify.breakpoint.lgAndUp);
    },
    env() {
      return ENV_DESCRIPTION;
    },
    hash() {
      return COMMITHASH;
    },
    userFullName() {
      return `${this.$user.firstname} ${this.$user.lastname}`;
    },
    userEmail() {
      return this.$user.email;
    },
    notificationObject() {
      return this.$store.state.notificationObject;
    },
    menuOpenComputed() {
      let menuState = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        menuState = this.$store.state.mainMenuOpen;
      }
      return menuState;
    },
    filteredSecondaryPages() {
      let pages = [];
      this.pages.forEach((page) => {
        if (!page.primary) {
          if (this.$hasMatchingRoles(page.roles)) {
            page.params.addressId = this.selectedAddress;
            pages.push(page);
          }
        }
      });
      return pages;
    },
    filteredPrimaryPages() {
      let pages = [];
      this.pages.forEach((page) => {
        if (page.primary) {
          if (this.$hasMatchingRoles(page.roles)) {
            page.params.addressId = this.selectedAddress;
            pages.push(page);
          }
        }
      });
      return pages;
    },
    address() {
      return this.$store.state.selectedAddress;
    },
  },
  async mounted() {
    if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
    this.handleSelectedBottomNavItem(this.$route.name);

    // Dont load addresses if invitation page is shown, only when navigate away from it
    if (this.$router.currentRoute.name !== "invitation") {
      await this.reloadProjects();
    } else {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.v-main__wrap {
  .v-list {
    .v-list-item--active {
      color: var(--v-accent-base) !important;
    }
  }
}

.v-card {
  border-radius: 0 !important;
}

.topbar-search {
  .v-input__slot {
    padding: 0 !important;

    &:after {
      display: none;
    }
  }
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-Book.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-BookOblique.otf");
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-BoldOblique.otf");
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-ExtraBold.otf");
  font-weight: 800;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-ExtraBoldOblique.otf");
  font-weight: 800;
  font-style: oblique;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-Heavy.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-HeavyOblique.otf");
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-LightOblique.otf");
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Futura Std";
  src: url("/fonts/FuturaStd-MediumOblique.otf");
  font-weight: 500;
  font-style: oblique;
}

$title-font-family: "Futura Std";
$body-font-family: "Futura Std";

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title {
    // To pin point specific classes of some components
    font-family: $title-font-family, sans-serif !important;
  }

  .headline {
    font-family: $title-font-family, sans-serif !important;
  }
}

.address-select {
  .v-select__selections {
    max-width: 90%;
  }
}

@media only screen and (min-width: 1264px) {
  .v-application {
    .v-toolbar__content,
    .v-toolbar__extension {
      padding-left: 35px;
    }
  }
}
</style>
