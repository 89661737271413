<template>
  <v-container fluid :fill-height="loading" class="contacts" pa-0>

    <v-app-bar color="black" dark fixed app>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          :placeholder="activeTab === 0 ? `${$t('contacts.search_from_contacts')}...` : `${$t('contacts.search_from_register_links')}...`"
          v-model="searchPhrase"
          data-cy="contacts_search"
          class="title text-uppercase topbar-search"
          style="font-size: 20px;"
          single-line
          full-width
          hide-details
          color="grey"
        >
          <hb-icon style="cursor: pointer;" slot="append">search</hb-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs v-model="activeTab" background-color="transparent" color="white" center-active>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab data-cy="contacts_tab_1" key="1">{{ $t('contacts.contacts') }}</v-tab>
          <v-tab data-cy="contacts_tab_2" key="2">{{ $t('contacts.register_household_appliance') }}</v-tab>
        </v-tabs>
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading" no-gutters>
        <v-col cols="12" class="contacts-holder">


          <v-tabs-items v-model="activeTab" style="background: transparent;">
            <v-tab-item key="1">

              <contacts-list :contacts="filteredContacts" />

              <template v-if="contacts && contacts.length === 0 && !loading">
                <v-col cols="12">
                  <v-row justify="center" align="center" no-gutters>
                    <div class="text-center pl-5 pr-5" style="padding-bottom: 150px;" :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px'}">
                      <hb-icon size="100" color="grey darken-3">chat</hb-icon>
                      <div class="headline font-weight-bold mt-3 mb-3">{{ $t('contacts.no_contacts') }}</div>
                      <div class="subtitle-1 grey--text text--lighten-2">{{ $t('contacts.no_contacts_help') }}.</div>
                      <v-btn @click="$reloadApp" class="mt-5" outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
                    </div>
                  </v-row>
                </v-col>
              </template>

            </v-tab-item>
            <v-tab-item key="2">

              <v-col cols="12" v-if="registerLinks.length > 0">
                <v-row no-gutters>
                  <v-alert :value="registerLinks.length > 0" type="info" outlined text class="mb-0">
                    {{ $t('contacts.register_links_help') }}
                  </v-alert>
                </v-row>
              </v-col>

              <RegisterLinksList :register-links="filteredRegisterLinks" />

              <template v-if="registerLinks && registerLinks.length === 0 && !loading">
                <v-col cols="12">
                  <v-row justify="center" align="center" no-gutters>
                    <div class="text-center pl-5 pr-5" style="padding-bottom: 150px;" :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px'}">
                      <hb-icon size="100" color="grey darken-3">chat</hb-icon>
                      <div class="headline font-weight-bold mt-3 mb-3">{{ $t('contacts.no_register_links') }}</div>
                      <div class="subtitle-1 grey--text text--lighten-2">{{ $t('contacts.no_register_links_help') }}.</div>
                      <v-btn @click="$reloadApp" class="mt-5" outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
                    </div>
                  </v-row>
                </v-col>
              </template>

            </v-tab-item>
          </v-tabs-items>

        </v-col>
      </v-row>
    </v-fade-transition>

  </v-container>
</template>

<script>
  import ContactsList from "../components/ContactsView/ContactsList.vue";
  import api from '../apicollection';
  import RegisterLinksList from "@/components/ContactsView/RegisterLinksList";

  export default {
    name: "ContactsView",
    components: {
      RegisterLinksList,
      ContactsList: ContactsList,
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
        activeTab: null,
        contacts: [],
        registerLinks: [],
      }
    },
    methods: {
      async reloadContacts() {
        this.loading = true;
        try {
          if (this.addressSelected) {
            this.contacts = await api.getContacts(this.selectedAddress);
          } else {
            this.contacts = await api.getAllContacts();
          }
          this.registerLinks = await api.getRegistrationLinks();
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_contacts'), true, this.reloadContacts);
        }
        this.loading = false;
      }
    },
    watch: {
      selectedAddress(newValue, oldValue) {
        this.reloadContacts();
      },
      activeTab() {
        this.searchPhrase = '';
      }
    },
    computed: {
      filteredContacts() {
        const contacts = this.contacts;
        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");

        return contacts.filter(c => {
          let found = [];

          const name = c.name.split(' ');
          const title = c.title.split(' / ')

          const lowerSearchedString =
            (c.company ? c.company.toLowerCase() : '') +
            (c.email ? c.email.toLowerCase() : '') +
            (name[0] ? name[0].toLowerCase() : '') +
            (name[1] ? name[1].toLowerCase() : '') +
            (c.phone ? c.phone.toLowerCase() : '') +
            (title[0] ? title[0].toLowerCase() : '') +
            (title[1] ? title[1].toLowerCase() : '');

          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        })
      },
      filteredRegisterLinks() {
        const registerLinks = this.registerLinks;
        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");

        return registerLinks.filter(l => {
          let found = [];

          const lowerSearchedString =
            (l.country ? l.country.toLowerCase() : '') +
            (l.manufacturer ? l.manufacturer.toLowerCase() : '');

          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          return found.indexOf(false) === -1;
        })
      },
      addressSelected() {
        const a = this.$store.state.selectedAddress;
        return a != null && a !== 0 && a !== '0';
      },
      selectedAddress() {
        return this.$store.state.selectedAddress;
      },
    },
    mounted() {
      this.$gaPageView();
      this.reloadContacts();
    },
  }
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
  .v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
  }
</style>
