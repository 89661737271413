<template>
    <v-badge overlap bordered offset-y="20" offset-x="20" icon="mdi-domino-mask" dot color="primary">
      <v-btn data-cy="mobile_menu_button" icon @click="toggleMainMenu">
        <hb-icon>menu</hb-icon>
      </v-btn>
    </v-badge>
</template>

<script>
export default {
  name: "MainMenuButton",
  methods: {
    toggleMainMenu() {
      this.$store.commit('toggleMainMenuState');
    },
  }
}
</script>

<style scoped>

</style>
