<template>
  <div v-if="document" :data-cy-fileinfo="document.info" :data-cy-filename="document.filename"
       :data-cy-url="document.url"
       @click="openDocument">
    <v-list-item class="pr-0">
      <v-list-item-content>
        <!-- SM AND UP -->
        <v-container v-if="$vuetify.breakpoint.smAndUp && !compact" fluid pa-0>
          <v-row no-gutters>
            <v-col :class="bold ? 'font-weight-medium' : ''" class="font-weight" sm="7">
              <div class="d-flex">
                <div>
                  <div>{{ document.info || document.filename }}</div>
                  <div v-if="document.info" class="text-subtitle-2" style="color: #ccc;">{{
                      document.filename
                    }}
                  </div>
                </div>
                <v-chip v-if="isNew" class="ml-2" color="primary" label x-small>{{
                    $t('new')
                  }}
                </v-chip>
              </div>
            </v-col>
            <v-col class="caption grey--text darken-1" sm="3">
              <a v-if="document.offerId" class="accent--text"
                 @click.stop="goToObject">{{ $t('documents.offer') }} {{ document.offerId }}</a>
              <a v-if="document.orderId" class="accent--text"
                 @click.stop="goToObject">{{ $t('documents.order') }} {{ document.orderId }}</a>
            </v-col>
            <v-col class="text-right caption grey--text darken-1" sm="2">
              {{ document.updatedAt ? $formatDate(document.updatedAt) : '' }}
            </v-col>
          </v-row>
        </v-container>

        <!-- XS ONLY -->
        <template v-if="$vuetify.breakpoint.xsOnly || compact">
          <v-list-item-title :class="bold ? 'font-weight-medium' : ''">
            <span>{{ document.info || document.filename }}</span>

          </v-list-item-title>
          <v-list-item-subtitle v-if="document.info" style="color: #ccc;">
            <span>{{ document.filename }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="caption grey--text darken-1">
            <div>
              {{ document.updatedAt ? $formatDate(document.updatedAt) : '' }}
            </div>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="caption grey--text darken-1">
            <div>
              <a v-if="document.offerId" class="accent--text"
                 @click.stop="goToObject">{{ $t('documents.offer') }} {{ document.offerId }}</a>
              <a v-if="document.orderId" class="accent--text"
                 @click.stop="goToObject">{{ $t('documents.order') }} {{ document.orderId }}</a>
            </div>
          </v-list-item-subtitle>
        </template>

      </v-list-item-content>

      <v-list-item-action>
        <v-menu bottom left @click.prevent.stop="">
          <template v-slot:activator="{ on }">
            <v-btn :data-cy-menu="document.info" icon v-on="on">
              <hb-icon>more_vert</hb-icon>
            </v-btn>
          </template>
          <v-list class="pa-0" dense>
            <v-list-item v-if="navigatorCanShare" @click="shareDocument">
              <v-list-item-avatar>
                <v-icon>mdi mdi-share</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>{{ $t('documents.share') }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="openDocument">
              <v-list-item-avatar>
                <v-icon>mdi mdi-download</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>{{ $t('documents.download') }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="isSelfUploaded" :disabled="$isImpersonating" :data-cy-delete="document.info" color="error"
                         @click="showConfirmDelete = true">
              <v-list-item-avatar>
                <v-icon :color="$isImpersonating ? 'grey darken-1' : ''">mdi mdi-delete-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>{{ $t('documents.delete') }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="() => {}">
              <v-list-item-avatar>
                <v-icon>close</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>{{ $t('close_menu') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>

    </v-list-item>

    <v-overlay v-model="loadingShareFile"
               :style="{paddingLeft: $vuetify.breakpoint.mdAndDown ? '' : $vuetify.breakpoint.lgAndUp && $store.state.miniMenu ? '80px' : '300px'}">
      <hb-loading-indicator align-middle/>
    </v-overlay>

    <v-dialog v-model="showConfirmDelete" max-width="300">
      <v-card light>
        <v-card-title class="black white--text">{{ $t('documents.confirm_delete') }}</v-card-title>
        <v-card-text class="pt-4">
          {{ $t('documents.confirm_delete_text', {fileName: document.filename}) }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :small="$vuetify.breakpoint.xsOnly" text @click="showConfirmDelete = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn :data-cy-confirm-delete="document.info" :loading="loadingDelete"
                 :small="$vuetify.breakpoint.xsOnly" color="primary" @click="deleteDocument">
            {{ $t('documents.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import mime from 'mime-types';

export default {
  emits: ['delete'],
  name: "OrderListItem",
  props: {
    document: {
      type: [Object, Boolean],
      default: false,
    },
    indent: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingShareFile: false,
      loadingDelete: false,
      showConfirmDelete: false,
    }
  },
  methods: {
    async deleteDocument() {
      this.loadingDelete = true;
      try {
        await axios.delete(`/api/Document/${this.document.id}`);
        this.showConfirmDelete = false;
        this.$showSuccessNotification(this.$t('documents.delete_success', {fileName: this.document.filename}), false);
        this.$emit('delete', this.document);
      } catch (e) {
        console.error(e);
        this.$showErrorNotification(this.$t('documents.delete_error', {fileName: this.document.filename}), false);
      }
      this.loadingDelete = false;
    },
    openDocument() {
      window.open(this.document.url, '_blank');
    },
    goToObject() {
      if (this.document.orderId) {
        this.$router.push({name: 'singleorder', params: {id: this.document.orderId}})
      }
      if (this.document.offerId) {
        this.$router.push({name: 'singleoffer', params: {id: this.document.offerId}})
      }
    },
    async shareDocument() {
      if (navigator.canShare) {
        this.loadingShareFile = true;
        try {
          const response = await axios.get(`/api/Document/${this.document.id}/download`, {
            responseType: 'arraybuffer',
          });

          this.loadingShareFile = false;

          const file = new File([response.data], this.document.filename, {type: mime.lookup(this.document.filename)});
          if (navigator.canShare({files: [file]})) {
            await navigator.share({title: this.document.filename, files: [file]});
          }
        } catch (error) {
          console.error(error);
        }

        this.loadingShareFile = false;
      } else {
        this.$showErrorNotification(this.$t('documents.browser_not_able_to_share'), false)
      }
    },
  },
  computed: {
    show() {
      return show
    },
    isSelfUploaded() {
      return this.document.customerCanEdit
    },
    isNew() {
      const documentDate = moment(this.document.updatedAt);
      const compareDate = moment().subtract(7, 'day');
      return documentDate.isAfter(compareDate);
    },
    navigatorCanShare() {
      return navigator.canShare;
    }
  }
}
</script>

<style scoped>

</style>
