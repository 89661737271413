<template>
  <v-snackbar v-model="dialog" top multi-line :timeout="-1" color="default">
    <div data-cy="notification_snackbar">
      <div class="subtitle-1 accent--text">{{ $t('push_notifications.do_you_want_to_allow_push_notifications') }}</div>
      <p class="my-1">{{ $t('push_notifications.push_notifications_explained') }}</p>
      <p class="my-1">{{ $t('push_notifications.info_for_agreeing') }}</p>
      <p class="my-1">{{ $t('push_notifications.info_for_declining') }}</p>

      <div class="action-buttons">
        <v-btn small color="primary" @click.native="cancel()" data-cy="deny_notifications">{{ $t('push_notifications.i_do_not_agree') }}</v-btn>
        <v-btn small color="primary" @click.native="agree()">{{ $t('push_notifications.i_agree') }}</v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import Notifications from "@/notifications";
import apiCollection from "@/apicollection";

export default {
  name: 'PushNotificationConfirm',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    agree() {
      this.dialog = false;
      this.enableNotifications();
    },
    async enableNotifications() {
      const notificationPermission = await Notifications.registerNotifications();
      if (notificationPermission) {
        try {
          await apiCollection.updateUser(this.$user.id, { language: this.$user.language, pushNotificationsEnabled: true });
          this.pushNotificationsEnabled = this.$user.pushNotificationsEnabled;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_updating_push_notification_settings'));
        }
      }
    },
    cancel() {
      this.dialog = false;
    },
  },
}
</script>

<style lang="scss">
  .action-buttons {
    margin-top: 15px;
    margin-bottom: 10px;

    .v-btn {
      margin-left: 0 !important;
      margin-right: 15px !important;
    }
  }
</style>
