import _ from 'lodash';

const buildStr = (rows) => {
  const grouped = _.groupBy(rows, 'roomNumber');
  const ret =  [];

  Object.keys(grouped).forEach(roomNumber => {
    const room = grouped[roomNumber][0];
    const roomRows = grouped[roomNumber];

    const groups = [];
    const groupedByGroupId = _.groupBy(roomRows, 'groupId');
    Object.keys(groupedByGroupId).forEach(gid => {
      const groupRows = groupedByGroupId[gid];
      const group1 = groupRows[0];

      const mainProducts = [];

      groupRows.filter(gr => gr.mainProduct === 1).forEach(mp => {
        const row = Object.assign({}, mp);
        row.productRows = groupRows.filter(gr => gr.mainProduct === 0 && gr.drawingId === mp.drawingId);
        mainProducts.push(row);
      });

      groups.push({
        groupId: group1.groupId,
        groupDescription: group1.groupDescription,
        mainProducts,
      })
    });

    ret.push({
      roomNumber: room.roomNumber,
      roomDescription: room.roomDescription,
      groups,
    });
  });

  return ret;
}

export default buildStr;
