<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on" :data-cy="selectedLanguage.dataCySelected">
        <v-list-item-action>
          <hb-icon>language</hb-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="body-2 font-weight-bold">{{ selectedLanguage.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-list>
      <v-list-item v-for="item in languageItems" :key="item.value" @click="languageChanged(item)" :input-value="selectedLanguage.value === item.value" :data-cy="item.dataCy">
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import api from "../apicollection";
  import i18n from '../i18n';

  export default {
    data() {
      return {
        languageItems: [
          {text: 'English', value: 'en', dataCySelected: 'selected_language_english', dataCy: 'language_english'},
          {text: 'Suomi', value: 'fi', dataCySelected: 'selected_language_finnish', dataCy: 'language_finnish'},
          {text: 'Svenska', value: 'sv', dataCySelected: 'selected_language_swedish', dataCy: 'language_swedish'},
        ],
        selectedLanguage: {text: 'Suomi', value: 'fi', dataCySelected: 'selected_language_finnish', dataCy: 'language_finnish'},
      }
    },
    watch: {
      currentLanguage(newValue, oldValue) {
        this.setSelectedLanguage(this.currentLanguage);
      }
    },
    computed: {
      currentLanguage() {
        return this.$user.language;
      }
    },
    methods: {
      async languageChanged(language) {
        try {
          await api.updateUser(this.$user.id, {
            language: language.value,
            pushNotificationsEnabled: this.$user.pushNotificationsEnabled,
          });
          window.location.reload();
        } catch (e) {
          console.log(e);
        }
      },
      setSelectedLanguage() {
        const userLanguageIndex = this.languageItems.findIndex(l => l.value === this.currentLanguage);
        if (userLanguageIndex > -1) {
          this.selectedLanguage = this.languageItems[userLanguageIndex];
        }
      }
    },
    mounted() {
      this.setSelectedLanguage();
    }
  }
</script>

<style scoped>

</style>
