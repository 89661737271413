<template>
    <v-icon v-bind="this.$attrs" class="material-icons-sharp"><slot></slot></v-icon>
</template>

<script>
  export default {
    name: "IconSharp",
    mixins: ['hb-icon']
  }
</script>

<style scoped>

</style>
