<template>
  <v-select
    v-if="userAddress && userHasMultipleActiveAddresses"
    dense
    v-model="selectedAddressId"
    :items="projects"
    outlined
    :label="$t('address')"
    :placeholder="$t('all_addresses')"
    hide-details
    item-text="streetAddress"
    item-value="id"
    class="address-select mb-3"
  >
    <template v-slot:selection="{ item }">
      <div style="max-width: 90%;" class="text-truncate body-2">{{ item.streetAddress }}</div>
    </template>
  </v-select>
</template>

<script>
  export default {
    name: "SelectedUserAddress",
    data() {
      return {
        selectedAddressId: 0,
      }
    },
    computed: {
      projects() {
        return this.$store.state.userProjects;
      },
      userAddress() {
        if (this.projects && this.projects.length > 0) {
          const selectedAddressIndex = this.projects.findIndex(p => p.id === this.selectedAddressId);
          if (selectedAddressIndex > -1) {
            return this.projects[selectedAddressIndex].streetAddress;
          }
        }
        return false;
      },
      userHasMultipleActiveAddresses() {
        return this.projects != null && this.projects.length > 1;
      },
      storeAddress() {
        return this.$store.state.selectedAddress;
      }
    },
    watch: {
      storeAddress(to) {
        if (to !== this.selectedAddressId) {
          this.selectedAddressId = to;
        }
      },
      selectedAddressId(to, from) {
        this.$store.commit('setSelectedAddress', to);

        const currentRoute = this.$router.currentRoute;
        const currentParams = currentRoute.params;

        if ('addressId' in currentParams && `${currentParams.addressId}` !== `${to}`) {
          const params = Object.assign({}, currentParams);
          params.addressId = to;
          if ('addressChangeRedirect' in currentRoute.meta) {
            const target = currentRoute.meta.addressChangeRedirect;
            this.$router.push({name: target, params});
          } else {
            this.$router.push({name: 'dashboard', params});
          }
        }
      }
    },
    mounted() {
      if (this.$store.state.selectedAddress) {
        this.selectedAddressId = this.$store.state.selectedAddress;
      }
    }
  }
</script>

<style scoped>

</style>
