import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './i18n.js';
import moment from 'moment';
import auth from './auth';
import axios from 'axios';
import _ from 'lodash';
import apicollection from "./apicollection";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    impersonate: null,
    mainMenuOpen: false,
    miniMenu: false,
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {
      },
    },
    showFullPage: true,
    previousRouteName: null,
    userProjects: null,
    selectedAddress: null,
  },
  mutations: {
    clearImpersonateData(state) {
      state.impersonate = null;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    toggleMiniMenu(state) {
      state.miniMenu = !state.miniMenu;
    },
    setImpersonateResponse(state, response) {
      state.impersonate = response;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setUserProjects(state, val) {
      state.userProjects = val;
    },
    setSelectedAddress(state, val) {
      state.selectedAddress = val;
    },
  },
  actions: {
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {
        },
      };
    },
    async reloadProjects(context) {
      try {
        const addresses = await apicollection.getAddresses();

        context.commit('setUserProjects', addresses);
        if (addresses.length === 0) {
          return;
        }

        if (context.state.selectedAddress == null) {
          const address = addresses[0];
          context.commit('setSelectedAddress', address.id);
        }
      } catch (error) {
        context.dispatch('showNotification',
          {
            color: 'error',
            message: i18n.t('errors.error_loading_projects'),
            timeOut: 10000,
            showButton: true,
            buttonText: i18n.t('try_again'),
            callback: () => {
              context.dispatch('reloadProjects');
            }
          });
        console.log(error);
      }
    },
  },
  getters: {
    getImpersonateObject(state) {
      return state.impersonate;
    }
  }
});
