<template>
  <v-simple-table class="mt-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">{{ $t('orders.identifier') }}</th>
          <th class="text-right px-2">{{ $t('orders.sum') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="payment in sortedPayments">
          <payment-row :key="payment.id" :payment="payment"></payment-row>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import PaymentRow from "@/components/SingleOrderView/PaymentRow";

export default {
  components: {PaymentRow},
  props: ['payments'],
  name: 'InvoiceDetails',
  computed: {
    sortedPayments() {
      const payments = [...this.payments];
      return payments.sort((a, b) => {
        return a.paarivi ? -1 : b.paarivi ? 1 : 0;
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
