<template>
  <div v-if="order">

    <v-list-item  @click="goToOrder(order)" :class="found ? 'order-item-found' : ''">
      <v-list-item-content>

        <!-- SM AND UP -->
        <v-container pa-0 fluid v-if="$vuetify.breakpoint.smAndUp">
          <v-row no-gutters>
            <v-col sm="3" class="font-weight" :class="bold ? 'font-weight-bold' : ''">
              <div :class="indent ? 'ml-3' : ''">{{ order.type ? order.type : backorder ? $t('orders.in_backorder') : $tc('orders.title', 1) }} #{{ order.id }}</div>
            </v-col>
            <template v-if="!backorder">
              <v-col sm="3" class="caption grey--text darken-1 pr-1">
                {{ order.info }}
              </v-col>
              <v-col sm="3" class="caption grey--text darken-1">
                {{ order.deliveryDate }}
              </v-col>
              <v-col sm="3" class="caption grey--text darken-1">
                {{ order.status }}
              </v-col>
            </template>
          </v-row>
        </v-container>

        <!-- XS ONLY -->
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-list-item-title :class="bold ? 'font-weight-bold' : ''">
            <div :class="indent ? 'ml-2' : ''">{{ order.type ? order.type : backorder ? $t('orders.in_backorder') : $tc('orders.title', 1) }} #{{ order.id }}</div>
          </v-list-item-title>
          <template v-if="!backorder">
            <v-list-item-title class="caption grey--text text--lighten-2 text-wrap" v-if="order.info != null && order.info.length > 0">
              <div :class="indent ? 'ml-2' : ''">{{ $t('orders.info') }}: {{ order.info }}</div>
            </v-list-item-title>
            <v-list-item-subtitle class="caption grey--text darken-1">
              <div :class="indent ? 'ml-2' : ''">{{ $t('orders.delivery_date') }}: {{ order.deliveryDate }}</div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="caption grey--text darken-1">
              <div :class="indent ? 'ml-2' : ''">{{ $t('orders.order_status') }}: {{ order.status }}</div>
            </v-list-item-subtitle>
          </template>
        </template>

      </v-list-item-content>

    </v-list-item>

  </div>
</template>

<script>
  export default {
    name: "OrderListItem",
    props: {
      order: {
        type: [Object, Boolean],
        default: false,
      },
      backorder: {
        type: [Boolean],
        default: false,
      },
      indent: {
        type: Boolean,
        default: false,
      },
      bold: {
        type: Boolean,
        default: true,
      },
      found: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      goToOrder(order) {
        if (this.backorder) {
          this.$router.push({ name: 'deliveries', params: { backorderId: order.id }})
        } else {
          this.$router.push({ name: 'singleorder', params: { id: order.id }})
        }
      }
    }
  }
</script>

<style lang="scss">
.order-item-found {
  .v-list-item__content {
    background: #282828;
  }
}
</style>
