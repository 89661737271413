/* eslint-disable no-param-reassign */
import Vue from 'vue';
import store from '../store.js';
import user from '../user';
import _ from 'lodash';

const UserPlugin = {
  install(/* vue */) {
    Vue.mixin({
      methods: {
        $hasMatchingRoles(roles) {
          return true;
        },
        $setUser(u) {
          user.user = u;
        },
      },
      computed: {
        $user() {
          return user.user;
        },
        $userHasMultipleAddresses() {
          const projects = store.state.userProjects;
          return projects != null && projects.length > 1;
        },
        $isImpersonating() {
          const imp = this.$store.state.impersonate;
          return (imp && typeof imp === 'object' && Object.keys(imp).length > 0);        }
      },
    });
  },
};

export default UserPlugin;
