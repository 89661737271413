<script>
import apicollection from "@/apicollection";
import store from "@/store";

export default {
  name: "ImpersonateLandingView",
  props: ['token'],
  data() {
    return {
      error: false,
      errorMessage: '',
      loading: true,
    }
  },
  async created() {
    const token = this.$route.params.token;
    try {
      const response = await apicollection.validateImpersonateToken(token)

      if (response) {
        store.commit('setImpersonateResponse', response);
      }
      location.href = window.location.origin;
    } catch (e) {
      this.error = true
      this.errorMessage = e;
    }
    this.loading = false;
  }
}
</script>

<template>
  <v-container :fill-height="loading" class="impersonate-landing">
    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>
    <v-row v-if="!loading" justify="center" no-gutters align="center">
      <v-alert v-if="error" type="error">{{ errorMessage }}</v-alert>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">

</style>
