<template>
  <div>
  </div>
</template>

<script>
  import axios from 'axios';
  import apicollection from "../apicollection";

  export default {
    props: ['notificationId'],
    name: 'NotificationView',
    methods: {
      goToSelectedAddress() {
        const aid = this.selectedAddress == null ? 0 : this.selectedAddress;
        this.$router.push({name: 'dashboard', params: { addressId: aid }});
      },
      async readNotification() {
        if (this.notificationId == null) {
          this.goToSelectedAddress();
          return;
        }

        try {
          const notification = await apicollection.openNotification(this.notificationId);
          const url = notification.redirectUrl;
          if (url != null && url !== '') {
            window.location = url;
          } else {
            this.goToSelectedAddress();
          }
        } catch (error) {
          console.log('error in notification redirect', error);
          this.goToSelectedAddress();
        }
      }
    },
    computed: {
      selectedAddress() {
        return this.$store.state.selectedAddress;
      }
    },
    mounted() {
      this.$gaPageView();
      this.readNotification();
    }
  }
</script>

<style scoped>

</style>
