/* eslint-disable no-param-reassign */
import Vue from 'vue';

const LocalePlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $locale() {
          return 'fi-FI';
        },
      }
    });
  },
};

export default LocalePlugin;
