import axios from 'axios';

// From https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function registerNotifications() {
  try {
    // Check that features is enabled
    if (!('Notification' in window) || !('serviceWorker' in navigator)) {
      return false;
    }

    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        return false;
      }
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();

    console.log('subscription', subscription);

    if (subscription == null) {
      const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC;
      if (vapidPublicKey == null || vapidPublicKey.length === 0) {
        console.log('invalid vapid public key, notifications disabled');
        return false;
      }

      const subscriptionOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
      };

      const newSubscription = await registration.pushManager.subscribe(subscriptionOptions);
      try {
        await axios.post('/api/User/subscription', newSubscription);
      } catch (err) {
        console.log(err);
      }
    }
    return true;
  } catch (error) {
    console.log(error);
  }
}

function getNotificationBrowserPermissions() {
  if (!('Notification' in window) || !('serviceWorker' in navigator)) {
    return 'disabled';
  }
  return Notification.permission;
}

export default { registerNotifications, getNotificationBrowserPermissions };
