<template>
  <div class="payment-row" :class="expanded ? 'expanded' : ''">
    <tr class="grey darken-4">
      <td>
        <div>{{ payment.info }}</div>
      </td>
      <td class="text-right px-2">
        {{ $formatNumberTo2Decimals(payment.summa, $user.language) }} {{ payment.valuutta }}
      </td>
      <td style="width: 44px; padding: 0" class="text-center">
        <v-btn
          icon
          :disabled="
            !payment.maksutapa &&
            !myymalaTyyppi &&
            !rahoitusTyyppi &&
            !payment.paymentInvoice &&
            !payment.advancePaymentInvoice
          "
          @click="toggleExpand"
          data-cy="expander"
        >
          <hb-icon v-if="expanded">mdi mdi-chevron-up</hb-icon>
          <hb-icon v-else>mdi mdi-chevron-down</hb-icon>
        </v-btn>
      </td>
    </tr>
    <tr v-if="loading">
      <td colspan="3" style="padding: 0">
        <v-progress-linear height="2px" color="white" indeterminate></v-progress-linear>
      </td>
    </tr>
    <tr v-if="expanded" class="expand-row">
      <td colspan="3" class="body-1">
        <advance-payment-invoice-item
          v-if="payment.advancePaymentInvoice"
          :invoice="payment.advancePaymentInvoice"
          :payment="payment"
        ></advance-payment-invoice-item>
        <payment-invoice-item
          v-if="(payment.paymentInvoice && payment.paymentInvoice.naytalasku) || payment.naytalasku "
          :invoice="payment.paymentInvoice"
          :payment="payment"
        ></payment-invoice-item>
        <payment-invoice-item v-if="!payment.advancePaymentInvoice && (!payment.paymentInvoice || !payment.paymentInvoice.laskunumero)" :payment="payment"></payment-invoice-item>
      </td>
    </tr>
  </div>
</template>

<script>
import api from "@/apicollection";
import PaymentInvoiceItem from "./PaymentInvoiceItem.vue";
import AdvancePaymentInvoiceItem from "./AdvancePaymentInvoiceItem.vue";

export default {
  components: { PaymentInvoiceItem, AdvancePaymentInvoiceItem },
  props: ["payment"],
  name: "PaymentRow",
  data() {
    return {
      loading: false,
      expanded: false,
    };
  },
  computed: {
    invoiceRows() {
      return [this.payment.paymentInvoice, this.payment.advancePaymentInvoice].filter(Boolean);
    },
    laskunumero() {
      return this.payment.laskunumero;
    },
    rahoitusTyyppi() {
      return this.payment.maksupostityyppi === "Rahoitus";
    },
    myymalaTyyppi() {
      return this.payment.maksupostityyppi === "Myymälä";
    },
  },
  methods: {
    toggleExpand() {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.expanded = true;
      }
    },
  },
};
</script>

<style lang="scss">
.payment-row {
  display: contents;
  width: 100%;

  &.expanded {
    tr,
    td {
      background-color: rgba(255, 255, 255, 0.025) !important;
    }
  }

  tr {
    width: 100%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.025) !important;
    }
  }

  td {
    padding: 8px 16px;
    vertical-align: middle;
    border-top: 1px solid #333;
  }

  .expand-row {
    td {
      border-top: none;
    }

    .payment-row-expand {
      width: 100%;

      tr {
        background-color: transparent !important;
      }

      th,
      td {
        padding: 4px 0;
        background-color: transparent !important;
        border-top: 1px solid #333;
        text-align: middle;
      }

      th {
        text-align: left;
      }
      td {
        text-align: right;
      }
    }
  }
}
</style>
