<template>
  <v-container :fill-height="loading" class="documents" fluid pa-0>

    <v-app-bar app color="black" dark fixed>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          v-model="searchPhrase"
          :placeholder="`${$t('documents.search_for_documents')}...`"
          class="title text-uppercase topbar-search"
          color="grey"
          data-cy="documents_search"
          full-width
          hide-details
          single-line
          style="font-size: 20px;"
        >
          <hb-icon slot="append" style="cursor: pointer;">search</hb-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="$vuetify.breakpoint.mdAndDown && $userHasMultipleAddresses" v-slot:extension>
        <hb-selected-address/>
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading" no-gutters>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <div class="text-center">
                    <v-btn @click="selectUploadFiles" color="primary" :disabled="$isImpersonating" :small="$vuetify.breakpoint.xsOnly" data-cy="add-file-button">
                      {{ $t('documents.add_your_own_documents') }}
                    </v-btn>
                    <div class="caption mt-2 grey--text">{{ $t('documents.max_file_size_and_type_hint') }}</div>
                  </div>
                  <v-file-input
                    data-cy="file-input"
                    id="file-upload-input"
                    style="display: none;"
                    outlined
                    :key="fileInputKey"
                    v-model="tempFiles"
                    :hint="$t('documents.max_file_size_and_type_hint')"
                    :label="$t('documents.add_your_own_documents')"
                    accept=".jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.gif,.GIF,.png,.PNG,.PDF,.pdf"
                    multiple
                    persistent-hint
                    show-size
                    @change="onFilesChange"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="documents-holder" cols="12" no-gutters>
          <v-progress-linear v-if="loadingSubtle" color="primary" indeterminate/>

          <document-tree-view :documents="documentsInTreeStructure"
                              :search-phrase="searchPhrase"
                              @reload-documents="reloadDocuments"
          />

          <template v-if="documents && documents.length === 0 && !loading">
            <v-col cols="12">
              <v-row align="center" justify="center" no-gutters>
                <div :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px'}"
                     class="text-center pl-5 pr-5"
                     style="padding-bottom: 150px;">
                  <hb-icon color="grey darken-3" size="100">insert_drive_file</hb-icon>
                  <div class="headline font-weight-bold mt-3 mb-3">{{
                      $t('documents.no_documents')
                    }}
                  </div>
                  <div class="subtitle-1 grey--text text--lighten-2">
                    {{ $t('documents.no_documents_help') }}.
                  </div>
                  <v-btn class="mt-5" outlined @click="$reloadApp">
                    <v-icon left>refresh</v-icon>
                    {{ $t('reload') }}
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </template>

        </v-col>
      </v-row>
    </v-fade-transition>

    <upload-files-dialog ref="fileUploadDialog"
                         :files="files"
                         :temp-files="tempFiles"
                         @cancel="resetUploadFiles"
                         @generate-random-string="fileInputKey = generateRandomString()"
                         @files-saved="reloadDocuments(true)" />

  </v-container>
</template>

<script>
import api from '../apicollection';
import moment from "moment";
import UploadFilesDialog from "@/components/DocumentsView/UploadFilesDialog";
import DocumentTreeView from "@/views/DocumentTreeView.vue";

const generateRandomString = function () {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
export default {
  name: "DocumentsView",
  components: {
    DocumentTreeView,
    UploadFilesDialog,
  },

  data() {
    return {
      loading: true,
      searchPhrase: '',
      documents: [],
      files: [],
      tempFiles: [],
      loadingSavingFiles: false,
      loadingSubtle: false,
      fileInputKey: generateRandomString(),
    }
  },
  methods: {
    generateRandomString,
    resetUploadFiles() {
      this.files = [];
      this.tempFiles = [];
      this.reloadDocuments(true);
    },
    async reloadDocuments(subtleLoading = false) {
      if (!subtleLoading)
        this.loading = true;
      else
        this.loadingSubtle = true;
      try {
        if (this.selectedAddress != null && this.selectedAddress !== 0 && this.selectedAddress !== '0') {
          this.documents = await api.getAddressDocuments(this.selectedAddress);
        } else {
          this.documents = await api.getAllDocuments();
        }
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_documents'), true, this.reloadDocuments);
      }
      this.loading = false;
      this.loadingSubtle = false;
    },
    selectUploadFiles() {
      document.getElementById('file-upload-input').click();
    },
    async onFilesChange(files) {
      const filesData = []
      for (const f of files) {
        if (f.size > 10000000) {
          this.$showErrorNotification(this.$t('documents.file_too_large', {fileName: f.name}), false);
          return;
        }
        let dataUrl;
        if (f.type.indexOf('image') > -1) {
          const reader = new FileReader();
          dataUrl = await new Promise(resolve => {
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(f);
          });
        }
        filesData.push({
          file: f,
          uploaded: false,
          uploadFailed: false,
          lisatiedot: '',
          type: "Asiakkaan lisäämät liitteet",
          id: this.generateRandomString(),
          dataUrl: dataUrl,
        })
      }
      this.files = filesData;
      this.$refs.fileUploadDialog.open();
    },
  },
  watch: {
    selectedAddress(newValue, oldValue) {
      this.reloadDocuments();
    },
  },
  computed: {
    selectedAddress() {
      return this.$store.state.selectedAddress;
    },
    sortedDocuments() {
      const documents = this.documents;
      documents.sort((a, b) => {
        if (b.updatedAt < a.updatedAt) return -1;
        if (b.updatedAt > a.updatedAt) return 1;
        return 0;
      });
      return documents;
    },
    documentsInTreeStructure() {
      const documents = this.sortedDocuments;
      const items = [];

      documents.forEach(d => {
        const path = d.type.split('|');
        d.name = d.filename;
        let currentLevel = items;

        let i = 0;
        path.forEach(part => {
          let existingIndex = currentLevel.findIndex(folder => folder.id === part);

          if (existingIndex > -1) {
            currentLevel = currentLevel[existingIndex].children;
          } else {
            let newPart = {id: part, name: part, updatedAt: d.updatedAt, children: []};
            currentLevel.push(newPart);
            currentLevel = newPart.children;
          }

          if (i === path.length - 1) {
            currentLevel.push(d);
          }
          i++;
        });
      });

      return items;
    },
  },
  mounted() {
    if (this.$vuetify?.lang?.locales?.en?.fileInput) {
      this.$vuetify.lang.locales.en.fileInput.counter = this.$t('file_input.counter');
      this.$vuetify.lang.locales.en.fileInput.counterSize = this.$t('file_input.counter_size');
    }
    this.reloadDocuments();
    this.$gaPageView();
  }
}
</script>

