<template>
  <div>
    <v-list-item :class="[mainProduct ? '' : 'ml-' + (1 + indent)]" :data-cy-other-item-order="item.orderId" :href="getOrderUrl">
      <v-list-item-content :class="found ? 'delivery-item-found' : ''" class="pl-3">
        <v-list-item-title class="text-wrap" :class="mainProduct ? '' : 'child-item-title'">
          <v-row v-if="$vuetify.breakpoint.smAndUp" align="center" no-gutters>
            <v-col cols="12" md="4">
              <div class="grey--text">
                {{ item.type }} <span class="grey--text text--darken-1">#{{ item.orderId }}</span>
              </div>
            </v-col>
            <v-col cols="7" md="4" class="text-wrap">
              <span class="font-weight-bold pr-2" :class="mainProduct ? 'item-avatar' : 'child-item-avatar'">{{ item.amount }} {{ $t('pcs') }}</span>
              {{ item.modelDescription }} ({{ item.model }}<template v-if="item.drawingId">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">, id: {{ item.drawingId }}</span>
                  </template>
                  <span>{{ $t('orders.drawing_id_tooltip', {count: item.drawingId}) }}</span>
                </v-tooltip>
              </template>)
              <template v-if="backorder">
                <div class="caption grey--text text-wrap" v-if="!item.confirmedDeliveryDate">
                  {{ item.confirmedDeliveryDate ? '' : item.deliveryEstimate ? `${$t('orders.estimated_delivery_date')}: ${item.deliveryEstimate}` : $t('orders.no_estimated_delivery_date') }}
                </div>
              </template>
            </v-col>
            <v-col cols="5" md="4" class="caption grey--text text-wrap">
              <span v-if="item.size && item.size.trim().length > 0">{{ $t('orders.size') }}: {{ item.size }}</span>
              <span v-if="item.size && item.size.trim().length > 0 && item.colorDescription && item.colorDescription.trim().length > 0">, </span>
              <span v-if="item.colorDescription && item.colorDescription.trim().length > 0">{{ $t('orders.color') }}: {{ item.colorDescription }}</span>
            </v-col>
          </v-row>
          <div class="text-wrap grey--text" v-if="$vuetify.breakpoint.xsOnly">
            {{ item.modelDescription }} ({{ item.model }}<template v-if="item.drawingId">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">, id: {{ item.drawingId }}</span>
                  </template>
                  <span>{{ $t('orders.drawing_id_tooltip', {count: item.drawingId}) }}</span>
                </v-tooltip>
              </template>)
          </div>
        </v-list-item-title>
        <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" class="caption text-wrap grey--text text--darken-1">
          <span v-if="item.size && item.size.trim().length > 0">{{ $t('orders.size') }}: {{ item.size }}</span>
          <span v-if="item.size && item.size.trim().length > 0 && item.colorDescription  && item.colorDescription.trim().length > 0">, </span>
          <span v-if="item.colorDescription && item.colorDescription.trim().length > 0">{{ $t('orders.color') }}: {{ item.colorDescription }}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" class="caption text-wrap grey--text text--darken-1">
          <span v-if="item.orderId">{{item.type ? item.type : $t('orders.order_number')}} #{{item.orderId}}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
<!--    <template v-if="item.productRows && item.productRows.length > 0">-->
<!--      <product-list-item v-for="(productRow, index) in item.productRows" :item="productRow" :main-product="false" :indent="indent + 1" :key="`${index+3456}_${productRow.groupId}`"></product-list-item>-->
<!--    </template>-->
  </div>
</template>

<script>
export default {
  name: "OtherDeliveryListItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    mainProduct: {
      type: Boolean,
      default: true,
    },
    indent: {
      type: Number,
      default: 0,
    },
    backorder: {
      type: Boolean,
      default: false,
    },
    found: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getOrderUrl() {
      if (!this.item.orderId) return '';
      return `${window.location.origin}/#/a/${this.item.addressId}/orders/${this.item.orderId}`
    },
  }
}
</script>

<style lang="scss" scoped>
.item-avatar {
  font-size: 14px;
}

.child-item-avatar,
.child-item-title {
  font-size: 13px;
}

.delivery-item-found {
  background: #353535;
}
</style>
