import Keycloak from 'keycloak-js'
import axios from 'axios'
import moment from 'moment'
import keycloakConfigDevelopment from '../../keycloak.Development.json'
import user from './user';

moment.locale('fi');

let keyCloakConfig;
if (process.env.NODE_ENV === 'production') {
  keyCloakConfig = {
    'VUE_APP_KeycloakAuthUrl': process.env['VUE_APP_KeycloakAuthUrl'],
    'VUE_APP_KeycloakRealm': process.env['VUE_APP_KeycloakRealm'],
    'VUE_APP_KeycloakClientId': process.env['VUE_APP_KeycloakClientId']
  }
} else {
  keyCloakConfig = keycloakConfigDevelopment
}

let keycloakClient = new Keycloak({
  url: keyCloakConfig['VUE_APP_KeycloakAuthUrl'],
  realm: keyCloakConfig['VUE_APP_KeycloakRealm'],
  clientId: keyCloakConfig['VUE_APP_KeycloakClientId']
});

function onAuthSuccess() {
  auth.client = keycloakClient;
  auth.accessTokenExpires = moment(auth.client.tokenParsed.exp * 1000).format('LLLL');
  auth.accessToken = auth.client.token;
  auth.idToken = auth.client.idToken;

  const userDataPromise = axios.get('api/User').then((response) => {
    const mypuustelliUser = response.data;
    auth.myPuustelliUser = mypuustelliUser;
    return mypuustelliUser;
  }).catch((error) => {
    console.log('error', error);
  });

  const authUserPromise = new Promise((resolve, reject) => {
    auth.client.loadUserProfile().then((profile) => {
      auth.user = profile;
      resolve();
    });
  });

  return Promise.all([userDataPromise, authUserPromise]);
}

keycloakClient.onAuthRefreshSuccess = onAuthSuccess;

async function init() {
  auth.client = keycloakClient;

  try {
    const authenticated = await keycloakClient.init({
      flow: 'standard',
      onLoad: 'login-required',
      checkLoginIframe: false
    });

    if (auth.success) {
      onAuthSuccess()
        .then(() => {
          auth.success(authenticated);
        });
      // Update/Create UI
    }
  } catch (error) {
    console.log(error);
    alert('failed to initialize');
  }
}

const auth = {
  client: null,
  accessTokenExpires: null,
  myPuustelliUser: null,
  user: null,
  role: '',
  success: null,
  authRefreshSuccess: null,
  accessToken: null,
  idToken: null,
  init,
};

export default auth;
