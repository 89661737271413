<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid :fill-height="loading" class="order">

    <v-app-bar color="black" dark fixed app>

      <hb-back-button fallback="orders" />

      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $tc('orders.title', 1) }}<span class="grey--text lighten-5">{{ order && order.id ? ` #${order.id}` : ''}}</span></v-toolbar-title>

     <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading">
        <v-col cols="12" xl="10" offset-xl="1"  class="order-holder" v-if="order">

          <template v-if="$vuetify.breakpoint.mdAndUp && order && order.showStepper">
            <v-stepper v-model="order.statusCode" alt-labels class="transparent elevation-0" data-cy="order_stepper">
              <v-stepper-header>
                <v-stepper-step color="accent" :complete="order.statusCode >= 0" step=""><div class="text-center">{{ $t('orders.statuses.0') }}</div></v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="accent" :complete="order.statusCode >= 10" step=""><div class="text-center">{{ $t('orders.statuses.10') }}</div></v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="success" :complete="order.statusCode >= 20" step=""><div class="text-center">{{ $t('orders.statuses.20') }}</div></v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </template>

          <template v-if="$vuetify.breakpoint.smAndDown && order && order.showStepper">
            <v-alert class="mt-5 mb-5" :value="true" icon="update" :color="order.statusCode === 20 ? 'success' : 'accent'" outlined>
              {{ order.statusText }}
            </v-alert>
          </template>

          <v-row>

            <v-col cols="12" md="8">
              <v-row>

                <v-col v-if="showDeliveryPreparationNotice" cols="12" class="pt-0 pb-5">
                  <v-alert
                    border="top"
                    colored-border
                    color="primary"
                    elevation="2"
                    class="mb-0 white"
                    type="info"
                    prominent
                  >
                    <div class="title primary--text mb-2">{{ $t('orders.delivery_date_is_approaching') }}</div>
                    <v-btn :to="{name: 'deliverypreparation', params: {id: order.id}}" outlined color="primary">{{ $t('orders.prepare_for_delivery') }}</v-btn>
                  </v-alert>
                </v-col>

                <template v-if="order.info">
                  <v-col cols="12" class="pt-0">
                    <v-card outlined color="#191919">
                      <v-card-text>
                        {{ order.info }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>

                <v-col cols="12" :class="order.info ? 'pt-0' : ''" v-if="order.showBillingDetails" data-cy="order_billing">
                  <v-card outlined color="#191919">
                    <v-card-title>{{ $t('customer.billing_details') }}</v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <div class="subtitle-2 white--text">{{ $t('customer.information') }}</div>
                          <div>{{ order.billingCustomerName1 }}</div>
                          <div v-if="order.billingCustomerName2">{{ order.billingCustomerName2 }}</div>
                          <div>{{ order.billingCustomerStreetAddress1 }}</div>
                          <div v-if="order.billingCustomerStreetAddress2">{{ order.billingCustomerStreetAddress2 }}</div>
                          <div>{{ order.billingCustomerPostalCode }} {{ order.billingCustomerCity }}</div>
                        </v-col>
                        <template v-if="payments && payments.length">
                          <v-col cols="12">
                            <div class="subtitle-2 white--text mt-4">{{ $t('orders.in_total')}}: {{ $formatNumberTo2Decimals(totalPaymentAmount, $user.language) }} {{ currency }}</div>
                          </v-col>
                          <v-col cols="12">
                            <p class="mt-4">{{ $t('orders.disclaimer') }}</p>
                          </v-col>
                        </template>
                        <v-col cols="12">
                          <invoice-details :payments="payments"/>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="order_delivery">
                    <v-card-title>{{ $t('customer.shipping_details') }}<v-chip class="ml-2" outlined small color="white" v-if="order.puustelliInstallation" label>{{ $t('orders.puustelli_installation')}}</v-chip></v-card-title>
                    <v-card-text>
                      <v-row dense>

                        <v-col cols="12" sm="6">
                          <div class="subtitle-2  white--text">{{ $t('customer.contact_person') }}</div>
                          <div>{{ order.deliveryContactPerson }} {{ order.deliveryPhone }}</div>

                          <div class="subtitle-2 mt-2 white--text">{{ $t('orders.delivery_address') }}</div>
                          <div>{{ order.deliveryCustomerName1 }}</div>
                          <div v-if="order.deliveryCustomerName2">{{ order.deliveryCustomerName2 }}</div>
                          <div>{{ order.deliveryStreetAddress1 }}</div>
                          <div v-if="order.deliveryStreetAddress2">{{ order.deliveryStreetAddress2 }}</div>
                          <div>{{ order.deliveryPostalCode }} {{ order.deliveryCity }}</div>
                        </v-col>

                        <v-col cols="12" sm="6" v-if="order && order.showDeliveryDetails" >
                          <div v-for="delivery in deliveries" :key="delivery.freightNumber" class="mb-2">
                            <div class="subtitle-2 white--text">{{ $t('supplier.title') }}: {{ delivery.supplier }}</div>
                            <div>{{ $t('supplier.carrier') }}: {{ delivery.freightCompany }}</div>
                            <div v-if="delivery.carPhone">{{ $t('supplier.truck_phone_number') }}: {{ delivery.carPhone }}</div>
                            <div v-if="delivery.deliveryTime">{{ $t('supplier.delivery_time') }}: {{ delivery.deliveryTime }}</div>
                            <div v-if="delivery.freightNumber">{{ $t('supplier.waybill') }}: {{ delivery.freightNumber }}</div>
                          </div>
                        </v-col>

                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="order_sales_person">
                    <v-card-title>{{ $t('supplier.vendor_contact_details') }}</v-card-title>
                    <v-card-text>
                      <div>{{ order.salesPerson }}{{ order.salesPersonPhone ? ' ' + order.salesPersonPhone : ''}}, {{ order.salesPersonEmail }}</div>
                      <div>{{ order.storeName }}, {{ order.storePhone }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <template v-if="productsByRooms && productsByRooms.length > 0">
                    <v-col cols="12">
                      <v-card outlined color="#191919" class="fill-height" data-cy="order_product_rows">
                        <v-card-title>{{ $t('orders.order_rows') }}</v-card-title>
                        <v-card-text class="pa-1">
                          <v-list color="transparent" v-if="productsByRooms && productsByRooms.length > 0">
                            <template v-for="room in productsByRooms">
                              <v-subheader :key="room.roomNumber">{{ room.roomDescription }}</v-subheader>
                              <template v-for="productGroup in room.groups">
                                <v-list-group :key="room.roomNumber + '_' + productGroup.groupId">
                                  <template v-slot:activator>
                                    <v-list-item-title>{{ productGroup.groupDescription }}</v-list-item-title>
                                  </template>
                                  <template v-for="(mainProduct, index) in productGroup.mainProducts">
                                    <product-list-item :item="mainProduct" :key="`${index+1234}_${mainProduct.groupId}`" />
                                    <v-divider :key="`${mainProduct.drawingId ? mainProduct.drawingId : index+1234}_${mainProduct.groupId}_${mainProduct?.strNumber}_divider`"></v-divider>
                                  </template>
                                </v-list-group>
                              </template>
                            </template>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <v-card outlined color="#191919" class="fill-height">
                      <v-card-title>{{ $t('orders.order_rows') }}
                        <v-spacer></v-spacer>
                        <v-btn class="ml-2" @click="reloadProductRows" :loading="loadingProductRows" small color="primary" data-cy="order_load_product_rows">{{$t('orders.load_product_rows')}}</v-btn></v-card-title>
                      <v-card-text class="pa-1">

                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>

              </v-row>
            </v-col>


            <v-col cols="12" md="4">

              <v-row  v-if="order.containsBackorders">
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="order_backorders">
                    <v-card-title>{{ $t('orders.order_has_backorders') }}</v-card-title>
                    <v-card-text>
                      <v-list one-line color="transparent">
                        <v-list-item :to="{name: 'singlebackorder', params: { id: this.order.id}}">
                          <v-list-item-icon>
                            <v-icon color="primary">fas fa-file-alt</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('orders.show_backorder') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row  v-if="order.showWarranty">
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="order_warranty">
                    <v-card-title>{{ $t('warranty.warranty') }}</v-card-title>
                    <v-card-text>
                      <v-list one-line color="transparent">
                        <v-list-item :to="{name: 'warrantycertificate', params: { id: this.order.id}}">
                          <v-list-item-icon>
                            <v-icon color="primary">fas fa-certificate</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('warranty.show_warranty_certificate') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="showRoomLinks && showRoomLinks.length > 0">
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="order_showroom_links">
                    <v-card-title>{{ $t('orders.3d_models') }}</v-card-title>
                    <v-card-text>
                      <v-list one-line color="transparent">
                        <template v-for="link in showRoomLinks">
                          <v-list-item  :key="link.showroomId" :href="link.link" target="_blank">
                            <v-list-item-icon>
                              <v-icon color="primary">fas fa-cube</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ link.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider :key="link.showroomId + '_divider'"></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="order_documents">
                    <v-card-title>{{ $t('orders.documents') }}</v-card-title>
                    <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
                      <template v-if="documents && documents.length">
                        <document-tree-view :documents="documentsInTreeStructure" compact @reload-documents="reloadDocuments"/>
                      </template>
                      <v-alert v-else :value="true" type="info" color="grey" outlined>
                        {{ $t('no_attachments') }}.
                      </v-alert>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
  import api from '../apicollection';
  import moment from 'moment';
  import ProductListItem from "../components/SingleOrderView/ProductListItem";
  import InvoiceDetails from "@/components/SingleOrderView/InvoiceDetails.vue";
  import DocumentTreeView from "@/views/DocumentTreeView.vue";

  export default {
    name: "SingleOrderView",
    components: {DocumentTreeView, InvoiceDetails, ProductListItem },
    props: {
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loadingInvoiceRows: {},
        invoiceRows: {},
        loading: true,
        searchPhrase: '',
        order: null,
        deliveries: [],
        payments: [],
        documents: [],
        productsByRooms: [],
        loadingProductRows: false,
        showRoomLinks: [],
        backorders: [],
      }
    },
    computed: {
      totalPaymentAmount() {
        let amount = 0;
        this.payments.forEach(p => amount += p.summa);
        return amount;
      },
      currency() {
        if (this.order && !!this.order.currency) {
          return this.order.currency;
        }
        return '';
      },
      showDeliveryPreparationNotice() {
        if (this.order != null) {
          return this.order.showDeliveryPreparation === true;
        }
        return false;
      },
      sortedDocuments() {
        const documents = this.documents;
        documents.sort((a, b) => {
          if (b.updatedAt < a.updatedAt) return -1;
          if (b.updatedAt > a.updatedAt) return 1;
          return 0;
        });
        return documents;
      },
      documentsInTreeStructure() {
        const documents = this.sortedDocuments;
        const items = [];

        documents.forEach(d => {
          const path = d.type.split('|');
          d.name = d.filename;
          let currentLevel = items;

          let i = 0;
          path.forEach(part => {
            let existingIndex = currentLevel.findIndex(folder => folder.id === part);

            if (existingIndex > -1) {
              currentLevel = currentLevel[existingIndex].children;
            } else {
              let newPart = {id: part, name: part, updatedAt: d.updatedAt, children: []};
              currentLevel.push(newPart);
              currentLevel = newPart.children;
            }

            if (i === path.length - 1) {
              currentLevel.push(d);
            }
            i++;
          });
        });

        return items;
      },
    },
    watch: {
      id(newValue, oldValue) {
        this.reloadOrder(newValue);
      }
    },
    methods: {

      async reloadProductRows() {
        try {
          this.loadingProductRows = true;
          const productRows = await api.getOrderProductRows(this.id);
          this.productsByRooms = productRows;
        } catch (error) {
          this.$showErrorNotification(this.$t('errors.error_loading_order'), false);
        }
        this.loadingProductRows = false;
      },
      async reloadOrder() {
        this.loading = true;
        try {
          const tasks = await Promise.all([
            api.getOrder(this.id),
            api.getOrderPayments(this.id),
            api.getOrderDeliveries(this.id),
            api.getOrderDocuments(this.id),
            api.getOrder3dModels(this.id),
          ]);
          // const order = await api.getOrder(this.id);
          // const payments = await api.getOrderPayments(this.id);
          // const deliveries = await api.getOrderDeliveries(this.id);
          // const documents = await api.getOrderDocuments(this.id);
          const order = tasks[0];
          const payments = tasks[1];
          const deliveries = tasks[2];
          const documents = tasks[3];
          const showroomLinks = tasks[4];
          // const productRows = tasks[4];
          this.order = order;
          this.payments = payments;
          this.deliveries = deliveries;
          this.documents = documents;
          this.showRoomLinks = showroomLinks;
          // this.productsByRooms = productRows;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_order'), true, this.reloadOrder);
        }
        this.loading = false;
      },
      async reloadDocuments() {
        this.loading = true;
        try {
          this.documents = await api.getOrderDocuments(this.id);
        } catch (error) {
          this.$showErrorNotification(this.$t('errors.error_loading_order'), false);
        }
        this.loading = false;
      },
    },
    mounted() {
      this.$gaPageView();
      this.reloadOrder();
      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      });
    },
  }
</script>

<style lang="scss" scoped>
</style>
