<template>
  <div>
    <table class="payment-row-expand">
      <tbody>
      <!--

      TÄMÄ ON PÄÄLASKU

      -->
        <tr v-if="!payment.advancePaymentInvoice"> <!-- Ei näytetä maksutapaa jos ennakkolasku on olemassa, jottei turhaan toistu -->
          <th>{{ $t("orders.payment_method") }}:</th>
          <td :data-cy="`${tunniste}-maksutapa`">{{ payment.maksutapa }}</td>
        </tr>
        <tr v-if="payment.rahoituslinkki">
          <th>{{ $t("orders.financing_link")}}:</th>
          <td data-cy="rahoituslinkki"><a :href="payment.rahoituslinkki" class="white--text" target="_blank">{{payment.rahoituslinkki}}</a></td>
        </tr>
        <template v-if="!payment.advancePaymentInvoice && !!payment.ennakkomaksu"> <!-- Ennakkolaskua ei luotu, mutta ennakkomaksu on olemassa -->
<!--          <tr>-->
<!--            <td colspan="2" data-cy="ennakkomaksutext" class="text-left">-->
<!--              {{ $t("orders.advance_payment_not_registered_info") }}-->
<!--            </td>-->
<!--          </tr>-->
          <tr>
            <th class="text-left">{{ $t("orders.advance_payment_amount") }}:</th>
            <td data-cy="ennakkomaksun-osuus">
              {{ $formatNumberTo2Decimals(payment.ennakkomaksu, $user.language) }}
              {{ payment.valuutta }}
            </td>
          </tr>
        </template>
        <template v-if="!invoice">
          <tr v-if="maksupostityyppi === 'Myymälä' && payment.maksuehtonro !== 'OPR'">
            <td colspan="2" class="text-left" data-cy="myymalatext">
              {{ $t("orders.payment_type_myymala_text") }}
            </td>
          </tr>
        </template>

        <!-- Lasku luotu -->
        <template v-else-if="invoice.naytalasku">
          <tr v-if="!!payment.advancePaymentInvoice">
            <td class="pt-5 text-left text-uppercase caption" colspan="2">
              {{ $t("orders.after_advance_payment") }}:
            </td>
          </tr>
          <tr>
            <th>{{ $t("orders.invoice_number") }}:</th>
            <td :data-cy="`${tunniste}-laskunumero`">{{ invoice.laskunumero }}</td>
          </tr>
          <tr>
            <th>{{ $t("orders.price_with_tax") }}:</th>
            <td data-cy="maksettavaa-yhteensa">
              {{ $formatNumberTo2Decimals(invoice.loppusumma, $user.language) }}
              {{ invoice.valuutta }}
            </td>
          </tr>
          <tr class="body-2 grey--text text--lighten-1">
            <th>{{ $t("orders.price_without_tax") }}:</th>
            <td data-cy="alviton">
              {{ $formatNumberTo2Decimals(invoice.veroton, $user.language) }}
              {{ invoice.valuutta }}
            </td>
          </tr>
          <tr class="body-2 grey--text text--lighten-1">
            <th>{{ $t("orders.tax_amount") }}:</th>
            <td data-cy="alv">
              {{ $formatNumberTo2Decimals(invoice.verosumma, $user.language) }}
              {{ invoice.valuutta }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("orders.due_date") }}:</th>
            <td data-cy="erapaiva">{{ $formatDateNoTime(invoice.erapvm) }}</td>
          </tr>

          <tr v-if="bankgiro">
            <th>{{ $t("orders.bankgiro") }}:</th>
            <td data-cy="bankgiro">{{ payment.bankgironumero }}</td>
          </tr>
          <template v-else>
            <tr>
              <th>{{ $t("orders.iban") }}:</th>
              <td data-cy="tilinumero">{{ payment.iban }}</td>
            </tr>
            <tr>
              <th>{{ $t("orders.bic") }}:</th>
              <td data-cy="bic">{{ payment.bic }}</td>
            </tr>
            <tr>
              <th>{{ $t("orders.reference_number") }}:</th>
              <td :data-cy="`${tunniste}-viite`">{{ invoice.viitenumero }}</td>
            </tr>
          </template>
          <tr v-if="invoice.laskutettupvm">
            <th>{{ $t("orders.advance_payment_created") }}:</th>
            <td data-cy="ennakko-laskutettupvm">
              {{ $formatDateNoTime(invoice.laskutettupvm) }}
            </td>
          </tr>
          <template v-else>
            <tr v-if="maksettu">
              <th>{{ $t("orders.price_with_tax_paid") }}:</th>
              <td data-cy="maksettu-yhteensa">
                {{ $formatNumberTo2Decimals(invoice.loppusumma, $user.language) }}
                {{ invoice.valuutta }}
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
    <div class="mt-4" v-if="!bankgiro && invoice && invoice.viivakoodi && !invoice.maksupvm && invoice.naytalasku">
      <v-btn
        @click="copyTextToClipboard(invoice.viivakoodi)"
        outlined
        color="white"
        block
        data-cy="viivakooditext"
      >
        <hb-icon v-if="!textCopied" left>mdi mdi-barcode</hb-icon>
        <v-fab-transition>
          <hb-icon v-if="textCopied" color="success" left>mdi mdi-check</hb-icon>
        </v-fab-transition>
        {{ $t("orders.copy_barcode") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: false,
    },
    payment: {
      type: Object,
      required: true,
    },
  },
  name: "PaymentInvoiceItem",
  data() {
    return {
      textCopied: false,
    };
  },
  computed: {
    bankgiro() {
      return this.payment.maanro === "SWE" && !!this.payment.bankgiro;
    },
    naytaEnnakkomaksuTeksti() {
      return (
        !!this.payment.ennakkomaksu &&
        (!!this.payment.advancePaymentInvoice === false ||
          !this.payment.advancePaymentInvoice.maksupvm ||
          (this.invoice && this.invoice.ennakkomaksettu && (this.invoice.ennakkomaksettu < this.invoice.loppusumma)))
      );
    },
    maksettu() {
      if (!this.invoice) return false;
      return !!this.invoice.maksupvm;
    },
    maksupostityyppi() {
      return this.payment.maksupostityyppi;
    },
    ennakkomaksuaSuoritettu() {
      if (!this.payment.advancePaymentInvoice) return false;
      return (
        !!this.invoice.ennakkomaksettu && this.invoice.ennakkomaksettu < this.invoice.loppusumma && !!this.payment.advancePaymentInvoice.laskutettupvm
      );
    },
    tunniste() {
      if (this.invoice && !this.invoice.paarivi) {
        return "ennakko";
      } else if (this.invoice && this.invoice.paarivi) {
        return "tehtaanlasku";
      } else if (this.payment.maksupostityyppi) {
        return this.payment.maksupostityyppi.toLowerCase();
      }
      return "tuntematon";
    },
  },
  methods: {
    copyTextToClipboard(text) {
      try {
        navigator.clipboard.writeText(text);
        this.textCopied = true;
        this.$showSuccessNotification(this.$t("orders.barcode_copied"));
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 410px) {
  td,
  th {
    padding: 2px;
    margin: 0;
    font-size: 14px;
  }
  table {
    width: 100%;
  }
}
</style>
