<template>
  <div>
    <v-list-item class="py-0" :class="mainProduct ? 'mt-3 mb-2' : 'ml-' + (1 + indent)">
      <v-list-item-action-text>
        <span class="font-weight-bold pr-2" :class="mainProduct ? 'white--text item-avatar' : 'gray--text child-item-avatar'">{{ item.amount }} {{ $t('pcs') }}</span>
      </v-list-item-action-text>
      <v-list-item-content class="py-0">
        <v-list-item-title class="text-wrap" :class="mainProduct ? '' : 'child-item-title'">
          <v-row v-if="$vuetify.breakpoint.smAndUp" align="center" no-gutters>
            <v-col cols="7" md="7" class="text-wrap pa-1">
              {{ item.modelDescription }} ({{ item.model }}<template v-if="item.drawingId">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">, id: {{ item.drawingId }}</span>
                  </template>
                  <span>{{ $t('orders.drawing_id_tooltip', {count: item.drawingId}) }}</span>
                </v-tooltip>
              </template>)
              <template v-if="backorder">
                <div class="caption grey--text text-wrap" v-if="!item.confirmedDeliveryDate">
                {{ item.confirmedDeliveryDate ? '' : item.deliveryEstimate ? `${$t('orders.estimated_delivery_date')}: ${item.deliveryEstimate}` : $t('orders.no_estimated_delivery_date') }}
                </div>
              </template>
            </v-col>
            <v-col cols="5" md="5" class="caption grey--text text-wrap pa-1">
              <span v-if="item.size">{{ $t('orders.size') }}: {{ item.size }}</span>
              <span v-if="item.size && item.colorDescription">, </span>
              <span v-if="item.colorDescription">{{ $t('orders.color') }}: {{ item.colorDescription }}</span>
            </v-col>
          </v-row>
          <template class="text-wrap" v-if="$vuetify.breakpoint.xsOnly">
            {{ item.modelDescription }} ({{ item.model }}<template v-if="item.drawingId">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">, id: {{ item.drawingId }}</span>
              </template>
              <span>{{ $t('orders.drawing_id_tooltip', {count: item.drawingId}) }}</span>
            </v-tooltip>
          </template>)
          </template>
        </v-list-item-title>
        <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" class="caption text-wrap">
          <span v-if="item.size">{{ $t('orders.size') }}: {{ item.size }}</span>
          <span v-if="item.size && item.colorDescription">, </span>
          <span v-if="item.colorDescription">{{ $t('orders.color') }}: {{ item.colorDescription }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <template v-if="item.productRows && item.productRows.length > 0">
      <product-list-item v-for="(productRow, index) in item.productRows" :item="productRow" :main-product="false" :indent="indent + 1" :key="`${index+3456}_${productRow.groupId}`"></product-list-item>
    </template>
  </div>
</template>

<script>
  export default {
    name: "ProductListItem",
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      mainProduct: {
        type: Boolean,
        default: true,
      },
      indent: {
        type: Number,
        default: 0,
      },
      backorder: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item-avatar {
    font-size: 14px;
  }

  .child-item-avatar,
  .child-item-title {
    font-size: 13px;
  }
</style>
