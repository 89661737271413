<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid :fill-height="loading" class="offer">

    <v-app-bar color="black" dark fixed app>

      <hb-back-button fallback="offers" />

      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $tc('offers.title', 1) }}<span class="grey--text lighten-5">{{ offer && offer.id  ? ` #${id}` : ''}}</span></v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading" no-gutters>
        <v-col cols="12" xl="10" offset-xl="1" class="order-holder">

          <v-row v-if="offer">
            <v-col cols="12" md="6" >
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="offer_details">
                    <v-card-title>{{ $t('offers.info') }}</v-card-title>
                    <v-card-text>
                      <div class="subtitle-2 white--text">{{ $t('offers.offer_number') }}</div>
                      <div>{{ offer.id }}</div>
                      <div class="subtitle-2 mt-2 white--text">{{ $t('offers.valid_until_date') }}</div>
                      <div>{{ $formatDateNoTime(offer.expirationDate) }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="sales_person_details">
                    <v-card-title>{{ $t('supplier.vendor_contact_details') }}</v-card-title>
                    <v-card-text>
                      <div>{{ offer.salesPerson }}{{ offer.salesPersonPhone ? ' ' + offer.salesPersonPhone : ''}}, {{ offer.salesPersonEmail }}</div>
                      <div>{{ offer.storeName }}, {{ offer.storePhone }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row v-if="showRoomLinks && showRoomLinks.length > 0">
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="3d_models">
                    <v-card-title>{{ $t('orders.3d_models') }}</v-card-title>
                    <v-card-text>
                      <v-list one-line color="transparent">
                        <template v-for="link in showRoomLinks">
                          <v-list-item :key="link.showroomId" :href="link.link" target="_blank">
                            <v-list-item-icon>
                              <v-icon color="primary">fas fa-cube</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ link.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle v-if="false">
                                {{ $t('orders.3d_version_date') }} todo
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider :key="link.showroomId + '_divider'"></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919" v-if="documents && documents.length > 0" data-cy="offer_documents">
                    <v-card-title>{{ $t('offers.documents') }}</v-card-title>
                    <v-card-text>
                      <v-list one-line color="transparent">
                        <template v-for="document in documents">
                          <v-list-item :key="document.id" :data-cy-filename="document.filename" :data-cy-url="document.url" :href="document.url" target="_blank">
                            <v-list-item-icon>
                              <v-icon color="primary">far fa-file-pdf</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ document.filename }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider :key="document.id + '_divider'"></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-col>
      </v-row>
    </v-fade-transition>


  </v-container>
</template>

<script>
  import api from '../apicollection';

  export default {
    name: "SingleOfferView",
    props: {
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
        offer: null,
        documents: [],
        showRoomLinks: [],
      }
    },
    watch: {
      id(newValue, oldValue) {
        this.reloadOffer(newValue);
      },
      selectedAddress(newValue, oldValue) {
        this.reloadOffer();
      }
    },
    methods: {
      async reloadOffer() {
        if (!this.selectedAddress) return;

        this.loading = true;
        try {
          const offer = await api.getOffer(this.id, this.selectedAddress);
          await this.reloadOfferDocuments();
          await this.reloadOffer3dModels();
          if (offer && offer.length > 0) {
            this.offer = offer[0];
          }
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_offer'), true, this.reloadOffer);
        }
        this.loading = false;
      },
      async reloadOfferDocuments() {
        this.loading = true;
        try {
          const documents = await api.getOfferDocuments(this.id);
          this.documents = documents;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_loading_offer_documents'), true, this.reloadOfferDocuments);
        }
        this.loading = false;
      },
      async reloadOffer3dModels() {
        try {
          const models = await api.getOffer3dModels(this.id);
          this.showRoomLinks = models;
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      },
    },
    computed: {
      selectedAddress() {
        return this.$store.state.selectedAddress;
      },
    },
    mounted() {
      this.reloadOffer();
      this.$gaPageView();

      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
      });
    },
  }
</script>

<style lang="scss" scoped>
</style>
