<template>
  <v-btn icon @click="goBack">
    <hb-icon>arrow_back</hb-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "BackButton",
    props: ['fallback', 'fallbackParams'],
    data() {
      return {}
    },
    methods: {
      goBack() {
        const ignoredRoutes = ['notification_redirect', 'invitation'];

        const previousRouteName = this.$store.state.previousRouteName;
        if (previousRouteName != null && ignoredRoutes.findIndex(r => r === previousRouteName) === -1) {
          this.$router.go(-1);
        } else {
          let routerFallback = {};
          routerFallback.name = this.fallback;
          if (this.fallbackParams) routerFallback.params = this.fallbackParams;
          this.$router.push(routerFallback);
        }
      },
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
