<template>
  <v-container fill-height pa-0 fluid class="invalid-invitation">
    <v-col cols="12" v-if="message !== ''">
      <v-row justify="center" align="center" no-gutters>
        <div class="text-center pl-5 pr-5" style="padding-bottom: 150px;" :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px'}">
          <hb-icon size="100" color="grey darken-3">access_time</hb-icon>
          <div class="headline font-weight-bold mt-3 mb-3">{{ $t('invitation.invalid_title') }}</div>
          <div class="subtitle-1 grey--text text--lighten-2">{{ message }}</div>
          <v-btn @click="logout" class="mt-5" outlined><v-icon left>logout</v-icon> {{ $t('logout') }}</v-btn>
        </div>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
  import api from '../apicollection';
  import auth from "../auth";
  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        message: ''
      }
    },
    methods: {
      logout() {
        auth.client.logout();
      }
    },
    async mounted() {
      this.$gaPageView();
      this.loading = true;
      try {
        const user = await api.acceptInvitation(this.id);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.messageKey != null) {
          this.$showErrorNotification(this.$t(error.response.data.messageKey), false);
        }
        console.log('error happened invitation', error);
      }

      const address = this.$store.state.selectedAddress;
      this.loading = false;
      if (address == null || address === 0) {
        this.$router.push({ name: 'dashboard', params: { addressId: 0 }});
      } else {
        this.$router.push({ name: 'dashboard', params: { addressId: address }});
      }
    }
  }
</script>

<style scoped>

</style>
