<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <v-container fluid :fill-height="loading" class="order">

    <v-app-bar color="black" dark fixed app>
      <hb-back-button fallback="orders" />
      <v-toolbar-title style="margin-left: 0;" v-if="!loading">{{ $t('orders.backorders_for_order') }}<span class="grey--text lighten-5">{{ order && order.id ? ` #${order.id}` : ''}}</span></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" fill-height align-middle></hb-loading-indicator>

    <v-fade-transition hide-on-leave>
      <v-row v-if="!loading">
        <v-col cols="12" xl="10" offset-xl="1" class="order-holder" v-if="order && backorders && backorders.length > 0">
          <v-row>
            <v-col cols="12" md="8" offset-md="2">
              <v-row>
                <v-col cols="12">
                  <v-card outlined color="#191919" data-cy="backOrderDetails">
                    <v-card-title>{{ $t('customer.shipping_details') }}</v-card-title>
                    <v-card-text>
                      <v-row dense>

                        <v-col cols="12" sm="6">
                          <div class="subtitle-2  white--text">{{ $t('customer.contact_person') }}</div>
                          <div>{{ deliveryAddressData.deliveryContactPerson }} {{ deliveryAddressData.deliveryPhone }}</div>

                          <div class="subtitle-2 mt-2 white--text">{{ $t('orders.delivery_address') }}</div>
                          <div>{{ deliveryAddressData.deliveryCustomerName1 }}</div>
                          <div v-if="deliveryAddressData.deliveryCustomerName2">{{ deliveryAddressData.deliveryCustomerName2 }}</div>
                          <div>{{ deliveryAddressData.deliveryStreetAddress1 }}</div>
                          <div v-if="deliveryAddressData.deliveryStreetAddress2">{{ deliveryAddressData.deliveryStreetAddress2 }}</div>
                          <div>{{ deliveryAddressData.deliveryPostalCode }} {{ deliveryAddressData.deliveryCity }}</div>
                        </v-col>

                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined color="#191919">
                    <v-card-title>{{ $t('orders.backorders') }}
                      <v-spacer></v-spacer>
                      <v-switch
                      v-model="showHidden"
                      :label="$t('orders.show_delivered')"
                      data-cy="show-hidden-orders"
                    ></v-switch>
                    </v-card-title>

                    <v-card-text class="pa-1">
                      <v-list color="transparent" v-if="groupedBackorders && Object.keys(groupedBackorders).length > 0" data-cy="backordersList">
                        <template v-for="backorderGroup in groupedBackordersKeys">
                          <v-list-group sub-group :value="!groupedBackorders[backorderGroup][0].delivered" :key="backorderGroup">
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title class="text-wrap" :class="groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].delivered && groupedBackorders[backorderGroup][0].delivered === true && 'text--disabled'" v-if="backorderGroup == 0">{{ $t('orders.no_freight_assigned') }}</v-list-item-title>
                                <v-list-item-title class="text-wrap" :class="groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].delivered && groupedBackorders[backorderGroup][0].delivered === true && 'text--disabled'" v-else>
                                  {{ backorderGroup }}
                                  <v-btn v-if="$vuetify.breakpoint.smAndUp && groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].trackingCodeLink !== null"
                                         @click.stop="openTrackingPage(groupedBackorders[backorderGroup][0].trackingCodeLink)"
                                         x-small
                                         class="ml-4"
                                         color="primary"
                                         dark
                                         data-cy="follow-transport-button"
                                  >
                                    {{ $t('orders.follow_cargo_link') }}
                                  </v-btn>
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn v-if="$vuetify.breakpoint.xsOnly && groupedBackorders && groupedBackorders[backorderGroup] && groupedBackorders[backorderGroup][0] && groupedBackorders[backorderGroup][0].trackingCodeLink !== null"
                                       @click.stop="openTrackingPage(groupedBackorders[backorderGroup][0].trackingCodeLink)"
                                       icon
                                       data-cy="follow-transport-button"
                                >
                                  <v-icon >mdi mdi-truck-fast-outline</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </template>
                            <template v-for="(backorder, index) in groupedBackorders[backorderGroup]">
                              <product-list-item backorder :item="backorder" :key="`${index+1234}_${backorder.drawingId}`" />
                              <v-divider :key="`${index+1234}_${backorder.drawingId}_divider`"></v-divider>
                            </template>
                          </v-list-group>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!--
                <template v-if="productsByRooms && productsByRooms.length > 0">
                  <v-col cols="12">
                    <v-card outlined color="#1d1d1d" class="fill-height">
                      <v-card-title>{{ $t('orders.order_rows') }}</v-card-title>
                      <v-card-text class="pa-1">
                        <v-list color="transparent" v-if="productsByRooms && productsByRooms.length > 0">
                          <template v-for="room in productsByRooms">
                            <v-subheader :key="room.roomNumber">{{ room.roomDescription }}</v-subheader>
                            <template v-for="productGroup in room.groups">
                              <v-list-group :key="room.roomNumber + productGroup.groupId">
                                <template v-slot:activator>
                                  <v-list-item-title>{{ productGroup.groupDescription }}</v-list-item-title>
                                </template>
                                <template v-for="(mainProduct, index) in productGroup.mainProducts">
                                  <product-list-item :item="mainProduct" :key="`${index+1234}_${mainProduct.groupId}`" />
                                  <v-divider :key="`${mainProduct.drawingId ? mainProduct.drawingId : index+1234}_${mainProduct.groupId}_divider`"></v-divider>
                                </template>
                              </v-list-group>
                            </template>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <v-card outlined color="#1d1d1d" class="fill-height">
                      <v-card-title>{{ $t('orders.order_rows') }}
                        <v-spacer></v-spacer>
                        <v-btn class="ml-2" @click="reloadProductRows" :loading="loadingProductRows" small outlined color="primary">{{$t('orders.load_product_rows')}}</v-btn></v-card-title>
                      <v-card-text class="pa-1">

                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                -->

              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import api from '../apicollection';
import ProductListItem from "../components/SingleOrderView/ProductListItem";
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
  name: "SingleBackOrderView",
  components: { ProductListItem },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      searchPhrase: '',
      order: null,
      backorders: [],
      showHidden: false,
    }
  },
  computed: {
    groupedBackordersKeys() {
      if (this.showHidden) {
        return Object.entries(this.groupedBackorders)
          .sort((a, b) => a !== null && b !== null && dayjs(a[1][0].confirmedDeliveryDate, 'DD.MM.YYYY') - dayjs(b[1][0].confirmedDeliveryDate, 'DD.MM.YYYY'))
          .map(a => a[0]);
      } else {
        const filteredGroupedBackorders = Object.entries(this.groupedBackorders).filter(item => item[1][0].delivered === false);
        return filteredGroupedBackorders
          .sort((a, b) => a !== null && b !== null && dayjs(a[1][0].confirmedDeliveryDate, 'DD.MM.YYYY') - dayjs(b[1][0].confirmedDeliveryDate, 'DD.MM.YYYY'))
          .map(a => a[0]);
      }

    },
    deliveryAddressData() {
      const order = this.order;
      const backorders = this.backorders;
      const deliveryAddressData = {};
      deliveryAddressData.deliveryContactPerson = order.deliveryContactPerson;
      deliveryAddressData.deliveryPhone = order.deliveryPhone;
      deliveryAddressData.deliveryCustomerName1 = order.deliveryCustomerName1;
      deliveryAddressData.deliveryCustomerName2 = order.deliveryCustomerName2;
      deliveryAddressData.deliveryStreetAddress1 = order.deliveryStreetAddress1;
      deliveryAddressData.deliveryStreetAddress2 = order.deliveryStreetAddress2;
      deliveryAddressData.deliveryPostalCode = order.deliveryPostalCode;
      deliveryAddressData.deliveryCity = order.deliveryCity;
      backorders.forEach(ba => {
        if (ba.city) {
          deliveryAddressData.deliveryContactPerson = ba.contactPerson;
          deliveryAddressData.deliveryPhone = ba.phone;
          deliveryAddressData.deliveryCustomerName1 = ba.name1;
          deliveryAddressData.deliveryCustomerName2 = ba.name2;
          deliveryAddressData.deliveryStreetAddress1 = ba.address1;
          deliveryAddressData.deliveryStreetAddress2 = ba.address2;
          deliveryAddressData.deliveryPostalCode = ba.postalCode;
          deliveryAddressData.deliveryCity = ba.city;
        }
      });
      return deliveryAddressData;
    },
    groupedBackorders() {
      return _.groupBy(this.backorders, (item) => {
        if (item.freightCompany) {
          let groupString = item.freightCompany;
          groupString = item.confirmedDeliveryDate ? `${groupString} - ${this.$t('orders.confirmed_delivery')}: ${item.confirmedDeliveryDate}` : groupString;
          return groupString
        }
        return 0;
      });
    },
  },
  watch: {
    id(newValue, oldValue) {
      this.reloadOrder(newValue);
    }
  },
  methods: {
    openTrackingPage(url) {
      window.open(url, 'blank')
    },
    async reloadOrder() {
      this.loading = true;
      try {
        const tasks = await Promise.all([
          api.getOrder(this.id),
          api.getOrderBackorders(this.id),
        ]);
        const order = tasks[0];
        const backorders = tasks[1];
        this.order = order;
        this.backorders = backorders;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_order'), true, this.reloadOrder);
      }
      this.loading = false;
    },
  },
  mounted() {
    this.$gaPageView();
    this.reloadOrder();
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
  },
}
</script>

<style lang="scss" scoped>
</style>
