/* eslint-disable no-param-reassign */
import Vue from 'vue';

const ValidationPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $rules() {
          return {
            required: value => !!value || this.$t('validation.field_is_required'),
          };
        },
      },
    });
  },
};

export default ValidationPlugin;
